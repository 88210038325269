const appDetailsStyles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  icon: { maxWidth: 100, aspectRatio: 1 },
};

export default appDetailsStyles;
