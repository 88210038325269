import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { Modal, Button, Input } from 'rsuite';

const DeleteReleaseModal = ({ open, onClose = () => {}, onSubmit = async () => {} }, ref) => {
  const [inputVal, setInputVal] = useState('');
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({});
  const [appInfo, setInfo] = useState('');

  const reset = () => {
    setInputVal('');
    setPayload({});
  };

  const handleClose = () => {
    if (!loading) {
      reset();
      onClose();
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    await onSubmit(payload);
    setLoading(false);
  };

  useImperativeHandle(ref, () => ({
    set: data => {
      setInfo({
        'App Name': data?.app_name,
        Version: data?.version,
        Build: data?.build_variant?.toUpperCase(),
        Platform: data?.platform,
      });
      setPayload({
        app_id: data?.app_id,
        app_name: data?.app_name,
        version: data?.sort_key_version,
      });
    },
    reset: () => {
      reset();
    },
  }));

  return (
    <Modal open={open} onClose={handleClose} backdrop='static' keyboard={false} size='sm'>
      <Modal.Header>
        <h6>Permanently Delete Release?</h6>
      </Modal.Header>
      <Modal.Body>
        <div style={{ padding: 2 }}>
          <table style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px' }}>
            {Object.entries(appInfo).map(([label, value], i) => (
              <tr key={`info-row-${i}`}>
                <td style={{ padding: '4px 4px 4px 8px' }}>{label}</td>
                <td style={{ padding: 4 }}>:</td>
                <td style={{ padding: '4px 8px 4px 4px', fontWeight: 600 }}>{value}</td>
              </tr>
            ))}
          </table>
        </div>
        <div style={{ padding: 2 }}>Note: Deleting this release will delete all the associated files.</div>
        <div style={{ padding: 2 }}>
          To confirm deletion, type <i>delete</i> in the text input field.
        </div>
        <Input value={inputVal} onChange={setInputVal} style={{ margin: 10, width: 300, borderColor: '#f88' }} placeholder='delete' />
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={loading} onClick={handleClose}>
          Cancel
        </Button>
        <Button disabled={inputVal?.toLocaleLowerCase() !== 'delete'} loading={loading} appearance='primary' color='red' onClick={handleDelete}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default forwardRef(DeleteReleaseModal);
