import React, { useState } from 'react';
import { Container, Content, Footer, Loader, Button, Panel } from 'rsuite';
import { Icons } from '../../assets';
import './styles.css';
import ArrowBackIcon from '@rsuite/icons/ArowBack';
import { Auth, Hub } from 'aws-amplify';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { isUserAuthenticated } from '../../services/auth-service';

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [signingIn, setSigningIn] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);

  Hub.listen('auth', ({ payload }) => {
    const { event, data } = payload;
    switch (event) {
      case 'signIn': {
        setSigningIn(true);
        sessionStorage.setItem('jwt', data?.signInUserSession?.accessToken?.jwtToken ?? '');
        break;
      }
      case 'cognitoHostedUI': {
        setSigningIn(true);
        checkSession();
        break;
      }
      case 'codeFlow': {
        setSigningIn(true);
        break;
      }
      default: {
        setSigningIn(true);
      }
    }
  });

  const checkSession = async () => {
    try {
      await isUserAuthenticated();
      navigate('/apps');
    } catch (error) {}
  };

  useEffect(() => {
    checkSession();
  }, []);

  useEffect(() => {
    if (location?.search?.startsWith('?code=')) {
      setSigningIn(true);
    }
  }, [location]);

  return (
    <Container style={{ height: '100%' }}>
      {signingIn && <Loader backdrop content={<span style={{ fontSize: 20, fontWeight: 700 }}>Signing In</span>} vertical />}
      <Content>
        <div className='main-container'>
          <Panel style={{ width: '35vw', minWidth: 400, maxWidth: 550 }} header={<h3>Login</h3>} bordered>
            {/* <Form fluid formValue={formValue} onChange={setFormValue}>
              <Form.Group controlId='email'>
                <Form.ControlLabel>Username or email address</Form.ControlLabel>
                <Form.Control name='email' />
              </Form.Group>
              <Form.Group controlId='password'>
                <Form.ControlLabel>Password</Form.ControlLabel>
                <Form.Control name='password' type='password' autoComplete='off' />
              </Form.Group>
              <Form.Group>
                <ButtonToolbar>
                  <Button onClick={handleLogin} disabled={!!Object.values(formValue).filter(el => el === '').length} appearance='primary'>
                    Sign in
                  </Button>
                </ButtonToolbar>
              </Form.Group>
            </Form> */}
            <Button
              color='blue'
              appearance='primary'
              disabled={signingIn}
              loading={buttonLoader}
              onClick={() => {
                setButtonLoader(true);
                Auth.federatedSignIn({
                  customProvider: 'Employees',
                });
              }}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 15, width: '100%', height: 50 }}>
              {!buttonLoader && (
                <>
                  <span style={{ fontSize: 20 }}>Continue to Federated Sign In</span>
                  <ArrowBackIcon rotate={180} style={{ fontSize: 30 }} />
                </>
              )}
            </Button>
          </Panel>
          <img src={Icons.dishASLogo} style={{ width: '40vw', maxWidth: 400 }} alt='' />
        </div>
      </Content>
      <Footer>Footer</Footer>
    </Container>
  );
};

export default Login;
