/* eslint-disable no-useless-escape */
import React, { useEffect, useState, useImperativeHandle } from 'react';
import { Input, Whisper, Tooltip, Checkbox, Uploader, IconButton, Dropdown, TagPicker, DatePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import AttachmentIcon from '@rsuite/icons/Attachment';
import AndroidIcon from '@rsuite/icons/Android';
import ImageIcon from '@rsuite/icons/Image';
import TrashIcon from '@rsuite/icons/Trash';
import GalleryWithRemove from './GalleryWithRemove';

export const UserInput = React.forwardRef(
  (
    {
      type = 'text',
      label = 'Default Label',
      placeholder = 'Default Input',
      note = '',
      value = '',
      required = false,
      disabled = false,
      onChange = () => {},
      labelStyle = { fontFamily: 'Source Sans Pro, sans-serif', fontSize: 16, marginBottom: 8, fontWeight: 600, color: '#413839' },
      inputStyle = { width: '100%', marginBottom: 4, fontSize: 16, color: '#000000', fontFamily: 'Source Sans Pro, sans-serif', fontWeight: 300 },
      errorStyle = { fontSize: 14, fontFamily: 'Source Sans Pro, sans-serif', fontWeight: 300, color: '#FF0000' },
      ...otherProps
    },
    ref,
  ) => {
    const [inputErrorText, setInputErrorText] = useState('');
    const [userInputText, setUserInputText] = useState(value);
    const [files, setFiles] = useState([]);
    const [isAppIconDeleting, setAppIconDeleteState] = useState(false);

    const binaryTypes = ['.apk', '.ipa'];

    const uploadFileTypes = type === 'image' ? 'image/*' : binaryTypes.join(',');
    const textAreaProps = type === 'textarea' ? { as: 'textarea', rows: otherProps?.rows ?? 3 } : {};
    const valueProp = value ? { value } : {};

    const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/i;

    const onUserInputChange = userText => {
      if (otherProps?.ignoreWhiteSpace) {
        userText = userText.replaceAll(' ', '');
      }
      setUserInputText(userText);
      onChange(userText);
      if (userText.length === 0 && required) {
        setInputErrorText('Cannot be empty!');
      } else if (inputErrorText !== '') {
        setInputErrorText('');
      }

      if (type === 'phone' && !phoneRegex.test(userText)) {
        setInputErrorText('Invalid input for phone number!');
      } else if (type === 'email' && !emailRegex.test(userText)) {
        setInputErrorText('Incomplete email id!');
      }
    };

    const compStyles = {
      div: {
        display: 'flex',
        flexDirection: 'column',
        width: '90%',
        alignSelf: 'flex-start',
        marginLeft: 8,
        marginBottom: otherProps?.bottom ?? 0,
      },
      label: labelStyle,
      note: {
        fontSize: 11,
        fontStyle: 'italic',
      },
      input: inputStyle,
      error: errorStyle,
    };

    const onSelectFiles = (newFiles, isReset = false) => {
      setFiles(newFiles);
      onChange(newFiles);
    };

    const deleteSelectedFile = async () => {
      try {
        setAppIconDeleteState(true);
        await otherProps?.onRemove();
        onSelectFiles([]);
        document.getElementsByName('file')[0].value = '';
      } finally {
        setAppIconDeleteState(false);
      }
    };

    useImperativeHandle(ref, () => ({
      resetFileInput() {
        setFiles([]);
        document.getElementsByName('file')[0].value = '';
      },
    }));

    useEffect(() => {
      setUserInputText(value);
      if (inputErrorText !== '' && value?.length > 0) {
        setInputErrorText('');
      }
    }, [value, inputErrorText]);

    switch (type) {
      case 'checkbox': {
        return (
          <div style={{ marginBottom: otherProps?.bottom ?? 0, display: 'flex', flexDirection: 'row' }}>
            <Checkbox
              defaultChecked={!!value}
              disabled={disabled}
              style={{
                fontFamily: 'Source Sans Pro',
                fontSize: 16,
                color: '#000000',
              }}
              {...valueProp}
              onChange={(prevValue, currentValue) => onChange(currentValue)}
              {...otherProps}>
              <label style={labelStyle}>{label}</label>
            </Checkbox>
          </div>
        );
      }
      case ['image', 'file'].find(el => el === type): {
        return (
          <div style={compStyles.div}>
            <label style={compStyles.label}>
              {label}
              {required ? ' (*)' : ''}
            </label>
            {note ? <label style={compStyles.note}>{`Note: ${note}`}</label> : null}
            <div style={{ display: 'flex', alignItems: 'flex-start', gap: 10 }}>
              {Array.isArray(value) && <GalleryWithRemove images={value} onRemove={otherProps?.onRemove} imageStyles={otherProps?.imageStyles} />}
              <Uploader fileListVisible={false} autoUpload={false} listType='picture' accept={uploadFileTypes} onChange={onSelectFiles} fileList={files} {...otherProps}>
                {otherProps?.multiple === true && files.length > 0 ? null : (
                  <button style={otherProps?.previewStyle ?? { width: 100, height: 100 }}>
                    {type === 'image' ? (
                      typeof value === 'string' && value ? (
                        <img src={value} alt={''} width='100%' height='100%' />
                      ) : (
                        <ImageIcon style={{ fontSize: 60 }} />
                      )
                    ) : type === 'file' && !value ? (
                      <AndroidIcon style={{ fontSize: 80 }} />
                    ) : (
                      <AttachmentIcon style={{ fontSize: 80 }} />
                    )}
                  </button>
                )}
              </Uploader>
              {otherProps?.multiple === false && <IconButton style={{ margin: 10 }} loading={isAppIconDeleting} onClick={deleteSelectedFile} icon={<TrashIcon />} circle size='sm' />}
            </div>
          </div>
        );
      }
      case 'dropdown': {
        const { options = [], noDefault = false, disabled = false } = otherProps;
        return (
          <div style={compStyles.div}>
            <label style={compStyles.label}>
              {label}
              {required ? ' (*)' : ''}
            </label>
            <Dropdown title={value ? value : 'Select'} activeKey={value ? value : null} onSelect={eventKey => onChange(eventKey)} menuStyle={{ width: 150 }} disabled={disabled} {...otherProps}>
              {noDefault && (
                <Dropdown.Item disabled eventKey={-1} key={-1}>
                  Select
                </Dropdown.Item>
              )}
              {options.map((option, i) => (
                <Dropdown.Item eventKey={option} key={i}>
                  {option}
                </Dropdown.Item>
              ))}
            </Dropdown>
          </div>
        );
      }
      case 'tag-picker': {
        const { options = [] } = otherProps;
        return (
          <div style={compStyles.div}>
            <label style={compStyles.label}>
              {label}
              {required ? ' (*)' : ''}
            </label>
            <TagPicker
              creatable
              data={options}
              groupBy='role'
              value={value}
              style={{ width: 224 }}
              placeholder={placeholder}
              onCreate={(value, item) => {
                onChange(value);
              }}
              onClean={() => onChange([])}
              onSelect={value => onChange(value)}
              {...otherProps}
            />
          </div>
        );
      }
      case 'date': {
        return (
          <div style={compStyles.div}>
            <label style={compStyles.label}>
              {label}
              {required ? ' (*)' : ''}
            </label>
            <DatePicker oneTap value={value} onChangeCalendarDate={onChange} {...otherProps} />
          </div>
        );
      }
      default: {
        return (
          <div style={compStyles.div}>
            <label style={compStyles.label}>
              {label}
              {required ? ' (*)' : ''}
            </label>
            <Whisper trigger={required ? 'focus' : 'none'} speaker={<Tooltip>Required</Tooltip>}>
              <Input
                type={type}
                disabled={disabled}
                style={compStyles.input}
                placeholder={placeholder}
                defaultValue={userInputText}
                value={userInputText}
                onChange={onUserInputChange}
                {...valueProp}
                {...textAreaProps}
                {...otherProps}
              />
            </Whisper>
            <label style={compStyles.error}>{inputErrorText}</label>
          </div>
        );
      }
    }
  },
);
