import { fetchQuery, get, uploadFile } from '../../services/api-service';
import { ENDPOINTS } from '../../services/endpoints';

export const loadAppScreenShots = async (items = []) => {
  const resolveScreenshotForItem = async item => {
    try {
      const signedUrl = await API.signedUrl.get(item);
      return signedUrl;
    } catch (error) {
      return item;
    }
  };
  try {
    return Promise.all(items?.map(resolveScreenshotForItem));
  } catch (error) {
    return items;
  }
};

export const API = {
  release: {
    get: async (appId, sortKeyVersion = null) => {
      try {
        const params = {
          app_id: appId,
        };
        if (sortKeyVersion) {
          params['version'] = sortKeyVersion;
        }
        const response = await fetchQuery({ url: ENDPOINTS.RELEASES, method: 'GET', params: params });
        return UTILS.release.resolveReleasesPayload(response);
      } catch (error) {
        return new Error(error);
      }
    },
    post: async payload =>
      new Promise(async (resolve, reject) => {
        try {
          const response = await fetchQuery({
            url: ENDPOINTS.RELEASES,
            method: 'POST',
            body: payload,
          });
          resolve(response);
        } catch (error) {
          reject(error);
        }
      }),
    put: async payload =>
      new Promise(async (resolve, reject) => {
        try {
          const response = await fetchQuery({
            url: ENDPOINTS.RELEASES,
            method: 'PUT',
            body: payload,
          });
          resolve(response);
        } catch (error) {
          reject(error);
        }
      }),
    delete: async (appId, appName, sortKeyVersion) =>
      new Promise(async (resolve, reject) => {
        try {
          const params = {
            app_id: appId,
            app_name: appName,
            version: sortKeyVersion,
          };
          const response = await fetchQuery({
            url: ENDPOINTS.RELEASES,
            method: 'DELETE',
            params: params,
          });
          resolve(response);
        } catch (error) {
          reject(error);
        }
      }),
  },
  signedUrl: {
    get: async path => {
      try {
        const {
          data: { signed_url: response },
        } = await get({ url: ENDPOINTS.SIGNED_URL, params: { path } });
        return response;
      } catch (error) {
        return new Error(error);
      }
    },
    post: async path =>
      new Promise(async (resolve, reject) => {
        try {
          const response = await fetchQuery({
            url: ENDPOINTS.SIGNED_URL,
            params: {
              path: path,
            },
            method: 'POST',
          });
          if (!response?.data?.signed_url) throw new Error('Unable to generate the signed URL. Try again.');
          resolve(response?.data?.signed_url);
        } catch (error) {
          reject(error);
        }
      }),
    upload: async (url, file) => {
      try {
        const response = await uploadFile({ url, file });
        return response;
      } catch (error) {
        return new Error(error);
      }
    },
  },
};

export const UTILS = {
  release: {
    resolveReleasesPayload: response => {
      const { items, latest_release_info } = response;

      if (items) {
        items.sort((a, b) => (a['release_date_time'] > b['release_date_time'] ? -1 : a['release_date_time'] < b['release_date_time'] ? 1 : 0));
      }

      return {
        latest_release_info,
        items: (items ?? [])?.map(item => ({
          ...item,
          sort_key_version: item?.version,
          version: item?.version?.split('::')?.[0],
          package_bundle_name: item?.platform === 'iOS' ? item?.bundle_identifier : item?.package_name,
        })),
      };
    },
  },
};
