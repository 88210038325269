import { get, post, deleteRequest } from '../../../../services/api-service';
import { ENDPOINTS } from '../../../../services/endpoints';

export const getUsers = () =>
  new Promise(async (resolve, reject) => {
    try {
      const response = await get({
        url: ENDPOINTS.USERS,
      });
      resolve(response?.data?.map(item => ({ ...item, group_name: item?.group_name?.split('MM_')?.[1] })));
    } catch (error) {
      reject(error);
    }
  });

export const addNewUser = payload =>
  new Promise(async (resolve, reject) => {
    try {
      const response = await post({
        url: ENDPOINTS.USERS,
        body: payload,
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });

export const deleteUser = payload =>
  new Promise(async (resolve, reject) => {
    try {
      const response = await deleteRequest({
        url: ENDPOINTS.USERS,
        body: payload,
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
