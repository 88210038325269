import { ENDPOINTS } from '../../../../services/endpoints';
import { get, post } from '../../../../services/api-service';

export const getGroups = () =>
  new Promise(async (resolve, reject) => {
    try {
      const response = await get({
        url: ENDPOINTS.GET_POST_GROUP,
      });
      resolve(response?.data);
    } catch (error) {
      reject(error);
    }
  });

export const addNewGroup = payload =>
  new Promise(async (resolve, reject) => {
    try {
      const response = await post({
        url: ENDPOINTS.GET_POST_GROUP,
        body: payload,
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
