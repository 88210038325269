import React, { useRef } from 'react';
import { Button, Table } from 'rsuite';
import { useNavigate } from 'react-router-dom';
import AppModal from './modals/AppModal';
import AppsTableConfig from './config/appsTableConfig.json';
import CustomCell from '../../components/TableCell/TableCell';
import styles from './styles';
import useFetchApps from './useFetchApps';

const { Column, HeaderCell, Cell } = Table;

const Apps = () => {
  const navigate = useNavigate();
  const { loading, apps, postApp, postAppStatus } = useFetchApps();

  const appModalRef = useRef(null);

  const onRowClick = ({ icon_path, icon_url, ...rowData }) => {
    navigate(rowData?.app_id, { state: { ...rowData, icons: icon_url?.map((url, index) => ({ name: icon_path?.[index]?.split('/')?.pop(), url: url, path: icon_path?.[index] })) } });
  };

  const onEdit = (event, data) => {
    event.stopPropagation();
    event.preventDefault();
    appModalRef?.current?.open();
    appModalRef?.current?.onEdit?.(data);
  };

  return (
    <div style={styles.root}>
      {/* Top Container */}
      <div style={styles.topContainer}>
        <Button appearance='primary' style={styles.createAppButton} onClick={() => appModalRef?.current?.open()}>
          Create New App
        </Button>
      </div>

      {/* Table Container */}
      <div style={styles.tableContainer}>
        <Table fillHeight={true} defaultExpandAllRows={true} style={{ width: '100%' }} data={apps} virtualized loading={loading} bordered cellBordered rowHeight={50} onRowClick={onRowClick}>
          {AppsTableConfig.map(({ id, header, type, columnOptions }, i) => (
            <Column {...columnOptions} key={i}>
              <HeaderCell style={{ color: '#7a7a7a', fontSize: 12, fontWeight: 700 }}>{header}</HeaderCell>
              <CustomCell type={type} dataKey={id} style={{ color: '#000000' }} onChange={postAppStatus} />
            </Column>
          ))}
          <Column>
            <HeaderCell style={{ color: '#7a7a7a', fontSize: 12, fontWeight: 700 }}></HeaderCell>
            <Cell align='center'>
              {rowData => (
                <span>
                  <Button appearance='link' onClick={e => onEdit(e, rowData)}>
                    Edit
                  </Button>
                </span>
              )}
            </Cell>
          </Column>
        </Table>
      </div>

      {/* Modal */}
      <AppModal ref={appModalRef} mode='create' onSubmit={payload => postApp(payload, () => appModalRef?.current?.close())} />
    </div>
  );
};

export default Apps;
