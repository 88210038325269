import React, { forwardRef, useCallback, useRef, useState, useImperativeHandle, useEffect } from 'react';
import { Modal, Button } from 'rsuite';
import { readImageAsDataUrl, loadAppImages } from '../async-handler';
import { UserInput } from '../../../components/UserInput/UserInput';
import AlertModal from '../../../components/AlertModal/AlertModal';

const AppModal = ({ mode = 'create', data = {}, onSubmit = async () => {} }, ref) => {
  const [open, setOpen] = useState(false);
  const [isAutoGenerateAppId, setIsAutoGenerateAppId] = useState(false);
  const [autoAppId, setAutoAppId] = useState('');
  const [userEnteredAppId, setUserEnteredAppId] = useState('');
  const [applicationName, setApplicationName] = useState('');
  const [appDescription, setAppDescription] = useState('');
  const [packageName, setPackageName] = useState('');
  const [bundleIdentifier, setBundleIdentifier] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [hasNotificationSupport, setHasNotificationSupport] = useState(true);
  const [accessibleBy, setAccessibleBy] = useState({
    options: ['Retailers', 'Technicians', 'Employees'].map(item => ({ label: item, value: item, role: 'Groups' })),
    selected: [],
  });
  const [icons, setIcons] = useState([]);
  const [screenshots, setScreenshots] = useState([]);

  const [deletedFiles, setDeletedFiles] = useState({
    icons: [],
    screenshots: [],
  });
  const [iconsError, setIconsError] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [onSubmitLoading, setOnSubmitLoading] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [editData, setEditData] = useState({});

  const uploaderRef = useRef(null);
  const iconsUploaderRef = useRef(null);
  const screenshotUploaderRef = useRef(null);
  const alertDialogRef = useRef(null);

  const accessibleByOptions = JSON.parse(sessionStorage.getItem('users')) ?? [];

  const clearForm = () => {
    setIsAutoGenerateAppId(false);
    setAutoAppId('');
    setUserEnteredAppId('');
    setApplicationName('');
    setAppDescription('');
    setPackageName('');
    setBundleIdentifier('');
    setIsActive(true);
    setHasNotificationSupport(true);
    setIcons([]);
    setIconsError(null);
    setScreenshots([]);
    setAccessibleBy({
      options: ['Retailers', 'Technicians', 'Employees'].map(item => ({ label: item, value: item, role: 'Groups' })),
      selected: [],
    });
    uploaderRef?.current?.resetFileInput?.();
    screenshotUploaderRef?.current?.resetFileInput?.();
    iconsUploaderRef?.current?.resetFileInput?.();
  };

  const onHandleClose = (doClearForm = false) => {
    if (doClearForm) {
      clearForm();
      setEditMode(false);
      setOpen(false);
    } else if (hasChanges) {
      alertDialogRef?.current?.open();
    } else {
      setEditMode(false);
      setOpen(false);
    }
  };

  const onAppId = appId => {
    setUserEnteredAppId(appId);
  };

  const onAppName = appName => {
    setApplicationName(appName);
  };

  const onAppDescription = description => {
    setAppDescription(description);
  };

  const onAutoAppIdCheckboxClick = isChecked => {
    setIsAutoGenerateAppId(isChecked);
    if (autoAppId === '') {
      let temp = Date.now();
      setAutoAppId(`${temp}`);
    }
  };

  const isValidIconName = str => {
    let [iconName, ext] = str.split('.');
    if (iconName.split('_').length !== 4) return false;
    const [appName, type, dimension, release] = iconName.split('_');
    if (!appName || !type || !dimension || !release) return false;
    if (!['icon', 'screenshot'].includes(type.toLowerCase()) || !['57', '152', '512'].includes(dimension)) return false;
    return true;
  };

  const onSelectAppIcons = async (files = []) => {
    const newImages = await Promise.all(
      files.map(async file => {
        const iconData = await readImageAsDataUrl(file?.blobFile);
        return {
          ...iconData,
          // name: `app_icon_${iconData?.width}x${iconData?.height}`,
        };
      }),
    );
    setIcons(prev => [...new Map([...prev, ...newImages].map(item => [item['name'], item])).values()]);
  };

  const onRemoveAppIcons = async data => {
    if (data?.image?.startsWith('https:')) {
      setDeletedFiles(prev => ({
        ...prev,
        icons: [...prev.icons, data],
      }));
    }
    setIcons(prev => prev.filter(icon => (icon?.path ? icon.path !== data?.path : icon?.image !== data?.image)));
  };

  const onSelectScreenshots = async (files = []) => {
    const newImages = await Promise.all(files.map(async file => await readImageAsDataUrl(file?.blobFile)));
    setScreenshots(prev => [...new Map([...prev, ...newImages].map(item => [item['name'], item])).values()]);
  };

  const onRemoveScreenshot = async data => {
    if (data?.image?.startsWith('https:')) {
      setDeletedFiles(prev => ({
        ...prev,
        screenshots: [...prev.screenshots, data],
      }));
    }
    setScreenshots(prev => prev.filter(screenshot => (screenshot?.path ? screenshot.path !== data?.path : screenshot?.image !== data?.image)));
  };

  const onSelectAccessibleBy = (selectedItems = []) => {
    // The selectedItems is an array of items checked at a given instant of time
    let newItems = accessibleBy.options;
    for (let item of selectedItems) {
      if (!accessibleBy.options.includes(item) && item.trim() !== '') {
        newItems.push(item);
      }
    }
    setAccessibleBy({
      options: newItems,
      selected: selectedItems,
    });
  };

  const resolvePayload = () => ({
    app_name: applicationName,
    app_id: isAutoGenerateAppId ? autoAppId : userEnteredAppId,
    description: appDescription,
    package_name: packageName,
    bundle_identifier: bundleIdentifier,
    is_active: isActive,
    has_notification_support: hasNotificationSupport,
    app_icons: icons,
    screenshots: screenshots,
    accessible_by: accessibleBy.selected?.join(','),
    _isEdit: isEditMode,
    _deletedFiles: deletedFiles,
  });

  const handleOnSubmit = async () => {
    const payload = resolvePayload();
    try {
      setOnSubmitLoading(true);
      await onSubmit(payload);
    } finally {
      setOnSubmitLoading(false);
      setEditData({});
      setEditMode(false);
    }
  };

  const isFormValid = useCallback(() => {
    const appId = isAutoGenerateAppId ? autoAppId : userEnteredAppId;
    return appId.trim().length > 0 && applicationName.trim().length > 0;
  }, [isAutoGenerateAppId, autoAppId, userEnteredAppId, applicationName]);

  useImperativeHandle(ref, () => ({
    open: () => {
      setOpen(true);
      clearForm();
    },
    close: () => {
      clearForm();
      setOpen(false);
    },
    onEdit: async data => {
      try {
        setEditMode(true);
        setEditData(data);
        const { app_id, app_name, description, package_name = '', bundle_identifier = '', is_active, has_notification_support, app_icons, screenshots, accessible_by } = data;
        setUserEnteredAppId(app_id);
        setApplicationName(app_name);
        setAppDescription(description);
        setPackageName(package_name);
        setBundleIdentifier(bundle_identifier);
        setIsActive(is_active);
        setHasNotificationSupport(has_notification_support);
        setIcons(await loadAppImages(app_icons));
        setScreenshots(await loadAppImages(screenshots));
        setAccessibleBy(prev => {
          const currentSelected = accessible_by.split(',');
          const selected = accessibleByOptions?.filter(el => currentSelected.includes(el.value)).map(el => el.value) ?? [];
          return {
            ...prev,
            selected,
          };
        });
      } catch (error) {}
    },
    clear: () => clearForm(),
  }));

  useEffect(() => {
    if (isEditMode) {
      const hasEditChanges =
        userEnteredAppId !== editData?.app_id ||
        applicationName.trim() !== editData?.app_name ||
        appDescription !== editData?.description ||
        packageName !== editData?.package_name ||
        bundleIdentifier !== editData?.bundle_identifier ||
        hasNotificationSupport !== editData?.has_notification_support ||
        isActive !== editData?.is_active ||
        deletedFiles?.icons?.length ||
        deletedFiles?.screenshots?.length;
      setHasChanges(!!hasEditChanges);
    } else {
      const isAppIdChanged = isAutoGenerateAppId ? autoAppId !== '' : userEnteredAppId !== '';
      const hasAddChanges =
        isAppIdChanged || applicationName.trim() !== '' || appDescription !== '' || hasNotificationSupport !== true || isActive !== true || icons?.length > 0 || screenshots?.length > 0;
      setHasChanges(!!hasAddChanges);
    }
  }, [
    isAutoGenerateAppId,
    autoAppId,
    userEnteredAppId,
    applicationName,
    appDescription,
    hasNotificationSupport,
    isActive,
    screenshots,
    accessibleBy,
    deletedFiles,
    editData,
    isEditMode,
    icons,
    packageName,
    bundleIdentifier,
  ]);

  // side effect to clear the fileList of the uploader component
  useEffect(() => {
    if (!screenshots.length) {
      screenshotUploaderRef?.current?.resetFileInput?.();
    }
    if (!icons.length) {
      iconsUploaderRef?.current?.resetFileInput?.();
    }
  }, [screenshots, icons]);

  // To set the error note for icons when filename is not in expected naming convention i.e <appName>_<type>_<dimension>_<variant>.<extension>
  useEffect(() => {
    const invalidIcons = icons.filter(file => !isValidIconName(file.name));
    if (invalidIcons.length) {
      setIconsError(`Incorrect file names - ${invalidIcons.map(file => file.name).join(', ')}`);
    } else {
      setIconsError(null);
    }
  }, [icons]);

  return (
    <Modal backdrop='static' keyboard={false} open={open} onClose={() => onHandleClose()} size='lg'>
      <Modal.Header>
        <Modal.Title style={{ fontWeight: 600, fontSize: 18 }}>{isEditMode || mode === 'edit' ? `Edit App` : `Create New App`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <UserInput
            label={'App Id'}
            value={isAutoGenerateAppId ? autoAppId : userEnteredAppId}
            disabled={isEditMode ? isEditMode : isAutoGenerateAppId}
            placeholder={'Enter unique number'}
            onChange={onAppId}
            required={true}
          />
          <UserInput disabled={isEditMode} type='checkbox' value={false} label={'Auto Generate App Id'} onChange={onAutoAppIdCheckboxClick} labelStyle={{}} bottom={16} />
          <UserInput label={'App Name'} placeholder={'Enter unique name'} disabled={isEditMode} value={applicationName} onChange={onAppName} required={true} bottom={24} />
          <UserInput type='textarea' label={'Description'} placeholder={'Enter description'} value={appDescription} onChange={onAppDescription} required={false} bottom={16} rows={5} />
          <UserInput label={'Package Name'} placeholder={'Package name for android'} value={packageName} onChange={val => setPackageName(val)} bottom={24} />
          <UserInput label={'Bundle Identifier'} placeholder={'Bundle Identifier for iOS'} value={bundleIdentifier} onChange={val => setBundleIdentifier(val)} bottom={24} />
          <UserInput type='checkbox' label={'Is Active?'} value={isActive} onChange={setIsActive} bottom={24} />
          <UserInput type='checkbox' label={'Has Notification Support?'} value={hasNotificationSupport} onChange={setHasNotificationSupport} bottom={24} />
          <UserInput
            type='tag-picker'
            placeholder='Pick/Add a Group or User'
            label={'Accessible By'}
            options={accessibleByOptions ?? accessibleBy.options}
            value={accessibleBy.selected}
            onChange={onSelectAccessibleBy}
            required={true}
            bottom={24}
          />
          {/* <UserInput type='image' label='App Icon' onChange={onSelectAppIcon} onRemove={() => onRemoveAppIcon(appIcon)} value={appIcon.base64} ref={uploaderRef} multiple={false} bottom={24} /> */}
          <UserInput
            type='image'
            label='App Icons'
            onChange={onSelectAppIcons}
            onRemove={onRemoveAppIcons}
            value={icons}
            ref={iconsUploaderRef}
            multiple={true}
            bottom={24}
            note='File name should be in the format <app name>_<type>_<dimension>_<release variant>.<extension> Eg. TestApp_icon_512_prod.png'
            imageStyles={{ width: 120, height: 120 }}
          />
          {iconsError && <div style={{ marginBottom: 10, color: 'red' }}>{iconsError}</div>}
          <UserInput
            type='image'
            label='App Screenshots'
            note='Please select files with unique name'
            onRemove={onRemoveScreenshot}
            onChange={onSelectScreenshots}
            value={screenshots}
            ref={screenshotUploaderRef}
            multiple={true}
            previewStyle={{ width: 120, height: 250 }}
          />
        </div>
        <AlertModal ref={alertDialogRef} body={`There are some unsaved changes. Proceeding will discard the changes. Do you like to close?`} onOk={() => onHandleClose(true)} />
      </Modal.Body>
      <Modal.Footer>
        <Button loading={onSubmitLoading} onClick={handleOnSubmit} disabled={!isFormValid()} appearance='primary' style={{ padding: '10px 30px' }}>
          Submit
        </Button>
        <Button onClick={() => onHandleClose()} appearance='subtle'>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default forwardRef(AppModal);
