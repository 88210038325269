import React from 'react';
import { post } from '../../../services/api-service';
import { ENDPOINTS } from '../../../services/endpoints';

const fetchIcons = paths => {
  return new Promise((resolve, reject) => {
    (async () => {
      try {
        const { items } = await post({
          url: ENDPOINTS.GET_ASSETS,
          params: { type: 'get' },
          body: {
            paths: paths,
          },
        });
        resolve(items);
      } catch (err) {
        reject(err);
      }
    })();
  });
};

const formatResponse = icons => {
  const response = [...icons];
  return response?.map(icon => ({
    id: icon?.request_path,
    path: icon?.request_path,
    name: icon?.request_path?.split('/')?.pop() ?? '',
    url: icon?.signed_url,
  }));
};

const useFetchAppIcons = iconPaths => {
  const [loading, setLoading] = React.useState(false);
  const [icons, setIcons] = React.useState([]);
  const [error, setError] = React.useState(null);

  const init = async () => {
    try {
      if (!iconPaths || !Array.isArray(iconPaths) || !iconPaths?.length) {
        return;
      }
      setLoading(true);
      let iconsData = await fetchIcons(iconPaths);
      iconsData = formatResponse(iconsData);
      setIcons(iconsData);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    let isCancelled = true;
    init();
    return () => {
      isCancelled = false;
      setError(null);
    };
  }, []);

  return { loading, error, icons };
};

export default useFetchAppIcons;
