import React from 'react';
import { Container } from 'rsuite';
import NavBar from '../components/Layout/NavBar';

const withNavBar = Component =>
  class extends React.Component {
    render() {
      return (
        <Container style={{ height: '100%' }}>
          <NavBar />
          <Component />
        </Container>
      );
    }
  };

export default withNavBar;
