import { ENDPOINTS } from '../../../../services/endpoints';
import { get, post, fetchQuery, deleteRequest } from '../../../../services/api-service';

export const getAdminUsers = () =>
  new Promise(async (resolve, reject) => {
    try {
      const requestUrl = ENDPOINTS.ADMIN_USERS;
      const response = await get({
        url: requestUrl,
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });

export const createAdminUser = payload =>
  new Promise(async (resolve, reject) => {
    try {
      const requestUrl = ENDPOINTS.ADMIN_USERS;
      const response = await post({
        url: requestUrl,
        body: payload,
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });

export const updateAdminUser = payload =>
  new Promise(async (resolve, reject) => {
    try {
      const requestUrl = ENDPOINTS.ADMIN_USERS;
      const response = await fetchQuery({
        url: requestUrl,
        method: 'PUT',
        body: payload,
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });

export const deleteAdminUser = params =>
  new Promise(async (resolve, reject) => {
    try {
      const requestUrl = ENDPOINTS.ADMIN_USERS;
      const response = await deleteRequest({
        url: requestUrl,
        method: 'DELETE',
        params: params,
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
