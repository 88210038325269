export const resolveAppsResponse = data => {
  if (!data || !Array.isArray(data) || !data.length) {
    return [];
  }
  return data?.map(app => {
    return {
      ...app,
      app_icon: getThumbnailIcon(app?.app_icons),
    };
  });
};

export const resolveUsersResponse = data => {
  let response = [];
  const { groups = [], users = [] } = data;
  const uniqueGroups = Array.from(new Set(groups.map(name => (name?.startsWith('MM_') ? name?.slice(3) : name))));
  uniqueGroups?.forEach(item => {
    response.push({
      label: item?.startsWith('MM_') ? item?.slice(3) : item,
      value: item,
      role: 'Groups',
    });
  });
  users?.forEach(item => {
    response.push({
      label: item,
      value: item,
      role: 'Users',
    });
  });
  return response;
};

export const resolvePlatformsResponse = data => {
  if (!(Array.isArray(data) && data.length)) {
    return [];
  }
  return data?.map(platform => ({
    label: platform,
    value: platform,
  }));
};

export const resolveUpdateAppPayload = data => {
  const { icon_file, app_icons: appIcons, screenshots, ...otherData } = data;
  const iconName = icon_file?.type ? `app-icon.${icon_file?.type?.split('/')?.[1] ?? 'png'}` : '';
  const uploadableAppIcons = appIcons?.filter(appIcon => appIcon?.image?.startsWith('data:'));
  const existingAppIcons = appIcons?.filter(appIcon => appIcon?.image?.startsWith('https:'));
  const uploadableScreenshots = screenshots?.filter(screenshot => screenshot?.image?.startsWith('data:'));
  const existingScreenshots = screenshots?.filter(screenshot => screenshot?.image?.startsWith('https:'));
  return {
    payload: {
      ...otherData,
      icon_file: iconName ? null : icon_file?.path ?? '',
      app_icons: existingAppIcons?.map(appIcon => appIcon?.path),
      screenshots: existingScreenshots?.map(screenshot => screenshot?.path),
    },
    icon: iconName ? { ...icon_file, name: iconName } : null,
    appIcons: uploadableAppIcons.length ? uploadableAppIcons : null,
    screenshots: uploadableScreenshots.length ? uploadableScreenshots : null,
  };
};

export const readImageAsDataUrl = inputFile => {
  const temporaryFileReader = new FileReader();
  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject('');
    };

    temporaryFileReader.onload = e => {
      let img = new Image();
      img.src = e?.target?.result;
      img.onload = e => {
        resolve({
          name: inputFile?.name?.replace(/[`~!@#$%^&*()|+=?;:'",<>\s\{\}\[\]\\\/]/gi, '').toLocaleLowerCase(),
          size: inputFile?.size,
          type: inputFile?.type,
          image: temporaryFileReader.result,
          width: e?.path?.[0]?.naturalWidth ?? img?.naturalWidth,
          height: e?.path?.[0]?.naturalHeight ?? img?.naturalHeight,
        });
      };
    };
    temporaryFileReader.readAsDataURL(inputFile);
  });
};

export const readImageFromUrl = urlString =>
  new Promise(async (resolve, reject) => {
    try {
      const url = new URL(urlString);
      const { pathname } = url;
      const name = pathname?.split('/')?.pop() ?? '';
      const response = await fetch({ url });
      const blob = await response.blob();
      const reader = new FileReader();
      reader.onloadend = () => resolve({ name, base64: reader.result });
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    } catch (error) {
      reject(error);
    }
  });

export const getThumbnailIcon = icons => {
  let res = '';
  if (!(Array.isArray(icons) && icons.length)) {
    return res;
  }
  res = icons?.find(icon => icon?.split('.')?.[0]?.endsWith(`152_prod`));
  if (!res) {
    res = icons?.find(icon => icon?.split('.')?.[0]?.endsWith(`512_prod`));
  }
  if (!res) {
    res = icons?.find(icon => icon?.split('.')?.[0]?.endsWith(`57_prod`));
  }
  if (!res) {
    res = icons?.[0];
  }
  return res;
};
