import React from 'react';
import { Content as RContent } from 'rsuite';

const Content = ({ children }) => {
  return (
    <RContent
      style={{
        padding: 10,
        border: '0.5px solid #efefef',
        backgroundColor: '#fff',
        margin: '5px 10px 5px 5px',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        height: 'calc(100vh - 150px) !important',
        overflow: 'auto',
      }}>
      {children}
    </RContent>
  );
};

export default Content;
