export default Object.freeze({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100%',
  },
  topContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    maxHeight: 40,
    marginBottom: 10,
  },
  createAppButton: {
    padding: '10px 30px',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    fontWeight: 700,
  },
  tableContainer: {
    display: 'flex',
    flex: 7,
    height: '100%',
  },
  editButton: {
    backgroundColor: 'transparent',
    color: 'blue',
  },
});
