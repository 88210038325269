import React, { useState } from 'react';
import { IconButton } from 'rsuite';
import TrashIcon from '@rsuite/icons/Trash';

const Image = ({ image, label, onRemove = async () => {}, style = {} }) => {
  const [isDeleting, setDeleteState] = useState(false);
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ position: 'relative', margin: 'auto' }}>
        <img src={image} alt='' width={style?.width ?? 150} height={style?.height ?? 250} />
        <IconButton
          loading={isDeleting}
          icon={<TrashIcon />}
          onClick={async () => {
            try {
              setDeleteState(true);
              await onRemove();
            } finally {
              setDeleteState(false);
            }
          }}
          style={{ position: 'absolute', top: 5, right: 5 }}
        />
      </div>
      <span style={{ fontSize: 10, fontStyle: 'italic' }}>{label}</span>
    </div>
  );
};

const GalleryWithRemove = ({ images = [], onRemove = () => {}, imageStyles = {} }) => {
  return (
    <div style={{ display: 'flex', gap: 10, flexWrap: 'wrap' }}>
      {images.map(({ image, name, path }, index) => (
        <Image image={image} label={name} key={index} onRemove={() => onRemove({ image, path })} style={imageStyles} />
      ))}
    </div>
  );
};

export default GalleryWithRemove;
