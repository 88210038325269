import React, { useEffect, useState } from 'react';
import { Sidebar, Sidenav, Nav, Divider } from 'rsuite';
import { useLocation, useNavigate } from 'react-router-dom';
import DashboardIcon from '@rsuite/icons/Dashboard';
import GroupIcon from '@rsuite/icons/legacy/Group';
import { Icons } from '../../assets';

const { dishASAppLogo } = Icons;

const NavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [activeKey, setActiveKey] = useState(location?.pathname ?? '/apps');

  useEffect(() => {
    if (location?.pathname) {
      setActiveKey(location?.pathname);
    }
  }, [location]);

  return (
    <Sidebar
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 10,
        backgroundColor: '#fff',
        margin: 10,
        marginLeft: 15,
        borderRadius: 5,
        border: '0.5px solid #ededed',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
      }}
      width={270}
      collapsible>
      <Sidenav.Header>
        <img alt='' src={dishASAppLogo} style={{ width: 240, padding: 10 }} />
      </Sidenav.Header>
      <Divider />
      <Sidenav.Body>
        <Nav activeKey={activeKey} vertical style={{ fontSize: 18 }}>
          <Nav.Item active={activeKey?.includes('apps')} icon={<DashboardIcon />} style={{ fontWeight: 700 }} onClick={() => navigate('/apps')}>
            Apps
          </Nav.Item>
          <Nav.Item active={activeKey?.includes('users')} icon={<GroupIcon />} style={{ fontWeight: 700 }} onClick={() => navigate('/usersgroups')}>
            Users & Groups
          </Nav.Item>
        </Nav>
      </Sidenav.Body>
    </Sidebar>
  );
};

export default NavBar;
