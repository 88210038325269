import { X_API_KEY } from './endpoints';
import { refreshToken } from './auth-service';
import { Hub } from 'aws-amplify';

const serialize = obj =>
  Object.entries(obj)
    .map(i => [i[0], encodeURIComponent(i[1])].join('='))
    .join('&');

const parseErrorResponse = async response => {
  const status = response?.status;
  const body = await response.json();
  const message = body?.errorMessage ?? body?.message ?? JSON.stringify(body);
  return {
    status,
    message,
  };
};

class ServerError extends Error {
  constructor({ status = 500, message = 'Internal Server Error' }) {
    super(`Status ${status}: ${message}`);
    this.name = 'ServerError';
    this.status = status;
    this.message = message;
  }
}

let retryCount = 0;
export const get = ({ url, params = {} }) =>
  new Promise(async (resolve, reject) => {
    try {
      const searchParams = typeof params === 'string' ? params : serialize(params);
      const requestUrl = `${url}${searchParams.length ? `?${searchParams}` : ''}`;
      const jwt = sessionStorage.getItem('jwt');
      if (!jwt) {
        throw new ServerError({ status: 401, message: 'Invalid credentials / No current authenticated user.' });
      }
      let response = await fetch(requestUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwt ?? '',
          'x-api-key': X_API_KEY,
        },
      });
      switch (response?.status) {
        case 200: {
          Hub.dispatch('Apps', {
            event: 'authorization-success',
            data: {
              status: response?.status,
              message: 'User is authorized to access the portal',
            },
          });
          resolve(await response.json());
          break;
        }
        case 401: {
          await refreshToken();
          retryCount++;
          if (retryCount < 3) {
            resolve(await get({ url, params }));
          } else {
            response.status = 403;
          }
          break;
        }
        case 403: {
          retryCount = 0;
          throw new ServerError({ status: 403, message: 'User is not authorized to access the portal' });
        }
        default: {
          throw new ServerError({ status: response?.status, message: response?.statusText });
        }
      }
    } catch (error) {
      if (error instanceof ServerError && error?.status === 403) {
        Hub.dispatch('Apps', {
          event: 'authorization-failure',
          data: {
            status: error?.status,
            message: error?.message,
          },
        });
      }
      reject(error);
    }
  });

export const post = ({ url, params = {}, body = {} }) =>
  new Promise(async (resolve, reject) => {
    try {
      const searchParams = typeof params === 'string' ? params : serialize(params);
      const requestUrl = `${url}${searchParams.length ? `?${searchParams}` : ''}`;
      const jwt = sessionStorage.getItem('jwt');
      let response = await fetch(requestUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwt ?? '',
          'x-api-key': X_API_KEY,
        },
        body: JSON.stringify(body),
      });
      const status = response.status;
      const data = await response.json();
      switch (status) {
        case 200:
        case 201: {
          resolve(data);
          break;
        }
        case 400: {
          reject({
            status,
            message: data?.message ?? '',
          });
          break;
        }
        case 401: {
          await refreshToken();
          resolve(await post({ url, params, body }));
          break;
        }
        case 403: {
          Hub.dispatch('Apps', {
            event: 'authorization-failure',
            data: {
              status: 403,
              message: 'User is not authorized to access the portal',
            },
          });
          break;
        }
        default:
          reject({
            status,
            message: data?.message ?? data,
          });
      }
    } catch (error) {
      reject(error);
    }
  });

export const uploadFile = ({ url, params = {}, file = null }) =>
  new Promise(async (resolve, reject) => {
    try {
      const jwt = sessionStorage.getItem('jwt');
      const requestPayload = {
        method: 'PUT',
        headers: {
          'Content-Type': file?.type ?? 'application/x-binary',
          // Authorization: jwt ?? '',
          'x-api-key': X_API_KEY,
        },
        body: file?.file,
      };
      const searchParams = typeof params === 'string' ? `${params}` : `${serialize(params)}`;
      const requestUrl = `${url}${searchParams.length ? `?${searchParams}` : ''}`;
      const response = await fetch(requestUrl, requestPayload);
      if (![200, 201].includes(response.status)) {
        reject(
          new Error({
            status: response?.status,
            errorMessage: response?.statusText,
          }),
        );
      }
      const data = await response.json();
      resolve(data);
    } catch (error) {
      // Hub.dispatch('Apps', {
      //   event: 'authorization-failure',
      //   data: {
      //     status: 403,
      //     message: 'User is not authorized to access the portal',
      //   },
      // });
      reject(error);
    }
  });

export const deleteRequest = ({ url, params = {}, body = {} }) =>
  new Promise(async (resolve, reject) => {
    try {
      const searchParams = typeof params === 'string' ? params : serialize(params);
      const requestUrl = `${url}${searchParams.length ? `?${searchParams}` : ''}`;
      const jwt = sessionStorage.getItem('jwt');
      const requestPayload = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwt ?? '',
          'x-api-key': X_API_KEY,
        },
        body: JSON.stringify(body),
      };
      const response = await fetch(requestUrl, requestPayload);
      if (![200, 201, 204].includes(response.status)) {
        reject(
          new Error({
            status: response?.status,
            errorMessage: response?.statusText,
          }),
        );
      }
      const data = await response.json();
      resolve(data);
    } catch (error) {
      Hub.dispatch('Apps', {
        event: 'authorization-failure',
        data: {
          status: 403,
          message: 'User is not authorized to access the portal',
        },
      });
      reject(error);
    }
  });

export const fetchQuery = async ({ url, params = {}, method = 'GET', body = {}, headers = {} }) =>
  new Promise(async (resolve, reject) => {
    try {
      const jwt = sessionStorage.getItem('jwt');
      const searchParams = typeof params === 'string' ? params : serialize(params);
      const requestUrl = `${url}${searchParams.length ? `?${searchParams}` : ''}`;
      const requestPayload = {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwt ?? '',
          'x-api-key': X_API_KEY,
          ...headers,
        },
      };
      if (method.toLocaleLowerCase() !== 'get') {
        requestPayload.body = JSON.stringify(body);
      }
      let response = await fetch(requestUrl, requestPayload);
      switch (response?.status) {
        case 200:
        case 201: {
          resolve(await response.json());
          break;
        }
        case 401: {
          const updatedToken = await refreshToken();
          response = await fetch(requestUrl, { ...requestPayload, headers: { ...requestPayload.headers, Authorization: updatedToken ?? '' } });
          break;
        }
        case 403: {
          Hub.dispatch('Apps', {
            event: 'authorization-failure',
            data: {
              status: 403,
              message: 'User is not authorized to access the portal',
            },
          });
          break;
        }
        default:
          reject(await parseErrorResponse(response.clone()));
          break;
      }
    } catch (error) {
      reject(error);
    }
  });
