import React from 'react';

const DetailRow = ({ label = '', value = '', separator = ':' }) => {
  return (
    <tr style={{ fontSize: 16 }}>
      <td style={{ padding: 5, width: '25%', fontWeight: 600 }}>{label}</td>
      <td>{separator}</td>
      <td style={{ padding: 5 }}>{value ? value : '-'}</td>
    </tr>
  );
};

export default DetailRow;
