import React from 'react';
import { addNewApp, deleteImage, updateApp, updateStatus } from './async-handler';
import { ENDPOINTS } from '../../services/endpoints';
import { get, post } from '../../services/api-service';
import { resolveAppsResponse, resolvePlatformsResponse, resolveUsersResponse } from './helper';
import { useToaster, Notification } from 'rsuite';

const fetchApps = () => {
  return new Promise((resolve, reject) => {
    (async () => {
      try {
        const { items, accessible_by, platforms } = await get({ url: ENDPOINTS.APPS });
        resolve({
          apps: resolveAppsResponse(items),
          accessibleBy: resolveUsersResponse(accessible_by),
          platforms: resolvePlatformsResponse(platforms),
        });
      } catch (err) {
        reject(err);
      }
    })();
  });
};

const fetchIcons = appsData => {
  return new Promise((resolve, reject) => {
    (async () => {
      try {
        const appIconPaths = appsData?.map(app => app?.app_icon)?.filter(icon => icon);
        const { items } = await post({
          url: ENDPOINTS.GET_ASSETS,
          params: { type: 'get' },
          body: {
            paths: appIconPaths,
          },
        });
        const updatedItems = [];
        for (const app of appsData) {
          const obj = Object.assign({}, app);
          for (const res of items) {
            const { request_path, signed_url } = res;
            if (app?.app_icon === request_path) {
              obj.app_icon = signed_url;
            }
          }
          updatedItems.push(obj);
        }
        resolve(updatedItems);
      } catch (err) {
        reject(err);
      }
    })();
  });
};

const fetchPostApp = data => {
  return new Promise((resolve, reject) => {
    (async () => {
      try {
        const { _deletedFiles, ...payload } = data;
        const response = await addNewApp(payload);
        resolve(response?.message);
      } catch (err) {
        reject(err);
      }
    })();
  });
};

const fetchPutApp = data => {
  return new Promise((resolve, reject) => {
    (async () => {
      try {
        const { _deletedFiles, ...payload } = data;
        const imagesToRemove = Object.values(_deletedFiles)?.flat() ?? [];
        const imagePaths = imagesToRemove?.map(img => img?.path);
        if (imagePaths.length) {
          await deleteImage({
            app_id: payload?.app_id,
            app_name: payload?.app_name,
            image_path: imagePaths ?? [],
          });
        }
        // await Promise.all([imagesToRemove?.map(async img => await deleteImage({ app_id: payload?.app_id, app_name: payload?.app_name, image_path: img?.path }))]);
        const response = await updateApp(payload);
        resolve(response);
      } catch (err) {
        reject(err);
      }
    })();
  });
};

const useFetchApps = () => {
  const toaster = useToaster();
  const [loading, setLoading] = React.useState(false);
  const [apps, setApps] = React.useState([]);
  const [accessibleBy, setAccessibleBy] = React.useState({});
  const [platforms, setPlatforms] = React.useState([]);
  const [error, setError] = React.useState(null);

  const init = async () => {
    try {
      setLoading(true);
      let response = await fetchApps();
      sessionStorage.setItem('users', JSON.stringify(response?.accessibleBy));
      sessionStorage.setItem('platforms', JSON.stringify(response?.platforms));
      setAccessibleBy(response?.accessibleBy);
      setPlatforms(response?.platforms);
      response.apps = await fetchIcons(response.apps);
      setApps(response?.apps);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const postApp = async (data, onSuccess = () => {}) => {
    try {
      const { _isEdit, ...payload } = data;
      const response = _isEdit ? await fetchPutApp(payload) : await fetchPostApp(payload);
      toaster.push(
        <Notification type='success' header='Success'>
          <div style={{ width: 150 }}>{response?.message}</div>
        </Notification>,
        { placement: 'topEnd' },
      );
      await init();
      onSuccess();
    } catch (err) {
      toaster.push(
        <Notification type='error' header='Error'>
          <div style={{ width: 150 }}>{err?.errorMessage ?? err?.message ?? JSON.stringify(err)}</div>
        </Notification>,
        { placement: 'topEnd' },
      );
    }
  };

  const postAppStatus = async ({ rowData, key, value }) => {
    try {
      if (window.confirm(`Would you like to ${value ? 'Enable' : 'Disable'} ${key === 'has_notification_support' ? 'Notification Support' : 'Active Status'} for ${rowData?.app_name}`)) {
        const payload = {
          app_id: rowData?.app_id,
          app_name: rowData?.app_name,
          [key]: value,
        };
        await updateStatus(payload);
        await init();
      }
    } catch (error) {
      console.error(error);
      toaster.push(
        <Notification type='error' header='Error'>
          <div style={{ width: 150 }}>{error?.message ?? `Unable to update ${rowData?.app_name}. Please try again.`}</div>
        </Notification>,
        { placement: 'topEnd' },
      );
    }
  };

  React.useEffect(() => {
    let isCancelled = true;
    init();
    return () => {
      isCancelled = false;
    };
  }, []);

  return { loading, error, apps, accessibleBy, platforms, refresh: init, postApp, postAppStatus };
};

export default useFetchApps;
