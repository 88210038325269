import React, { useEffect, useRef, useState } from 'react';
import { getGroups } from './async-handlers';
import { Container, Button, Table, useToaster, Notification, Form } from 'rsuite';
import CustomCell from '../../../../components/TableCell/TableCell';
import { GROUPS_TABLE_CONFIG } from '../../config';
import { addNewGroup } from './async-handlers';

const { Column, HeaderCell, Cell } = Table;

const Groups = ({ classes = {} }) => {
  const toaster = useToaster();
  const formRef = useRef(null);
  const [groups, setGroups] = useState({
    loading: true,
    items: [],
    error: null,
  });
  const [formData, setFormData] = useState({
    loading: false,
    data: {
      groupName: '',
      description: '',
    },
    error: null,
  });
  const [showForm, setShowForm] = useState(false);

  const fetchData = async () => {
    try {
      setGroups(prev => ({ ...prev, loading: true }));
      const response = await getGroups();
      setGroups(prev => ({ ...prev, items: response, loading: false }));
    } catch (error) {
      setGroups(prev => ({ ...prev, items: [], error: error }));
    }
  };

  const handleAddNewGroup = async () => {
    try {
      setFormData(prev => ({ ...prev, loading: true }));
      await addNewGroup(formData.data);
      toaster.push(
        <Notification type='success' header='Success'>
          <div style={{ width: 150 }}>{`Added ${formData.data?.groupName} successfully`}</div>
        </Notification>,
        { placement: 'topEnd' },
      );
      setFormData({ loading: false, data: { groupName: '', description: '' }, error: null });
      formRef?.current?.root?.reset();
      setShowForm(false);
    } catch (error) {
      toaster.push(
        <Notification type='error' header='Error'>
          <div style={{ width: 150 }}>{error?.message ?? `Unable to add ${formData.data?.groupName}. Please try again.`}</div>
        </Notification>,
        { placement: 'topEnd' },
      );
      setFormData(prev => ({ ...prev, loading: false, error: error }));
    } finally {
      await fetchData();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container style={{ height: '100%' }}>
      <div className={classes.dataContainer}>
        <div className={classes.topContainer}>
          {/* <span className={classes.title}>Groups</span> */}
          {showForm ? (
            <Form ref={formRef} layout='inline' className={classes.form} onChange={formValue => setFormData(prev => ({ ...prev, data: formValue }))}>
              <Form.Group className={classes.formGroup} controlId='groupName'>
                <Form.ControlLabel>Group Name</Form.ControlLabel>
                <Form.Control name='groupName' style={{ width: 160 }} />
                <Form.HelpText tooltip>Required</Form.HelpText>
              </Form.Group>

              <Form.Group className={classes.formGroup} controlId='description'>
                <Form.ControlLabel>Description</Form.ControlLabel>
                <Form.Control name='description' type='textarea' style={{ width: 160 }} />
              </Form.Group>
              <Button appearance='primary' type='submit' loading={formData.loading} onClick={() => handleAddNewGroup()} className={classes.formBtn}>
                Submit
              </Button>
              <Button
                className={classes.formBtn}
                onClick={() => {
                  formRef?.current?.root?.reset();
                  setShowForm(false);
                }}>
                Cancel
              </Button>
            </Form>
          ) : (
            <Button appearance='primary' className={classes.createBtn} onClick={() => setShowForm(true)}>
              Create group
            </Button>
          )}
        </div>
        <div className={classes.tableContainer}>
          <Table fillHeight={true} defaultExpandAllRows={true} width={'100%'} data={groups.items} virtualized loading={groups.loading} bordered cellBordered rowHeight={50}>
            {GROUPS_TABLE_CONFIG.map(({ id, header, type, columnOptions }, i) => (
              <Column {...columnOptions} key={i}>
                <HeaderCell style={{ color: '#7a7a7a', fontSize: 12, fontWeight: 700 }}>{header}</HeaderCell>
                <CustomCell type={type} dataKey={id} style={{ color: '#000000' }} />
              </Column>
            ))}
            {/* <Column>
                <HeaderCell style={{ color: '#7a7a7a', fontSize: 12, fontWeight: 700 }}></HeaderCell>
                <Cell align='center'>
                  {rowData => (
                    <span>
                      <Button appearance='link' onClick={e => onEditGroup(e, rowData)}>
                        Edit
                      </Button>
                    </span>
                  )}
                </Cell>
              </Column> */}
          </Table>
        </div>
      </div>
    </Container>
  );
};

export default Groups;
