import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Modal, Button } from 'rsuite';

const AlertModal = ({ title = 'Alert', body = '', onOk = () => {} }, ref) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  return (
    <Modal backdrop='static' role='alertdialog' size={'xs'} open={open} onClose={handleClose}>
      <Modal.Header>
        <Modal.Title style={{ fontWeight: 600 }}>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button onClick={onOk} appearance='primary' color='red'>
          Proceed
        </Button>
        <Button onClick={handleClose} appearance='default'>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default forwardRef(AlertModal);
