import { ENDPOINTS } from '../../services/endpoints';
import { deleteRequest, fetchQuery, get, post } from '../../services/api-service';
import { loadAppScreenShots as baseLoadAppIcons } from '../AppDetails/async-handlers';
import { resolveUsersResponse, resolveUpdateAppPayload, getThumbnailIcon, resolvePlatformsResponse } from './helper';
export { readImageAsDataUrl, readImageFromUrl } from './helper';

const getImage = async path => {
  try {
    const {
      data: { signed_url: response },
    } = await get({ url: ENDPOINTS.SIGNED_URL, params: `path=${path}` });
    return response;
  } catch (error) {
    return new Error(error);
  }
};

const loadAppIcons = async (items = []) => {
  const resolveIconForItem = async item => {
    try {
      const { app_icons, ...otherAttr } = item;
      if (!app_icons || (Array.isArray(app_icons) && app_icons.length === 0)) return item;
      else {
        if (app_icons && Array.isArray(app_icons) && app_icons.length) {
          const signedUrls = await Promise.all([...app_icons.map(async iconPath => await getImage(iconPath))]);
          const appIcon = await getImage(getThumbnailIcon(app_icons));
          return { ...otherAttr, icon_path: app_icons, icon_url: signedUrls, app_icon: appIcon };
        }
      }
    } catch (error) {
      return item;
    }
  };
  try {
    return Promise.all(items?.map(resolveIconForItem));
  } catch (error) {
    return items;
  }
};

export const getAllApps = async () => {
  try {
    const { items, accessible_by, platforms } = await get({ url: ENDPOINTS.APPS });
    sessionStorage.setItem('users', JSON.stringify(resolveUsersResponse(accessible_by)));
    sessionStorage.setItem('platforms', JSON.stringify(resolvePlatformsResponse(platforms)));
    return await loadAppIcons(items);
  } catch (error) {
    console.error(error);
  }
};

export const uploadImage = async ({ type, ...payload }) =>
  new Promise(async (resolve, reject) => {
    try {
      const {
        data: { request_status },
      } = await post({
        url: ENDPOINTS.IMAGE,
        body: payload,
      });
      if (request_status === 'success') {
        resolve({ status: request_status, path: payload?.image_path, type });
      } else {
        throw new Error({ status: request_status, path: payload?.image_path, type, message: 'Unable to upload the image' });
      }
    } catch (error) {
      reject(error);
    }
  });

export const addNewApp = async receivedPayload =>
  new Promise(async (resolve, reject) => {
    try {
      const {
        screenshots,
        // icon_file: iconFile,
        app_icons: appIcons,
        app_id,
        app_name,
      } = receivedPayload;

      // Get the bucket path for icon and/or screenshots
      const bucketPathPayload = {
        app_id,
        app_name,
        files: [],
      };
      let bucketPathResponse = { data: { icon: '', screenshots: [] } };

      if (Array.isArray(appIcons) && appIcons.length > 0) {
        bucketPathPayload.files.push({ type: 'app_icon', names: appIcons?.map(icon => icon?.name) ?? [] });
      }

      if (Array.isArray(screenshots) && screenshots.length > 0) {
        bucketPathPayload.files.push({ type: 'screenshots', names: screenshots?.map(screenshot => screenshot?.name) ?? [] });
      }

      if ((appIcons && Array.isArray(appIcons) && appIcons.length) || (screenshots && Array.isArray(screenshots) && screenshots.length)) {
        bucketPathResponse = await post({
          url: ENDPOINTS.POST_GET_BUCKET_PATH,
          body: bucketPathPayload,
        });
      }

      const { app_icon: iconPaths, screenshots: screenshotsPaths } = bucketPathResponse;
      // Upload the images and capture the status
      const imageUploadStatuses = await Promise.all([
        ...(iconPaths ? iconPaths : [])?.map(async iconPath => {
          const iconName = iconPath.split('/').pop();
          const icon = appIcons?.find(el => el.name === iconName);
          const iconPayload = {
            app_id,
            app_name,
            image_path: iconPath,
            image_data: icon?.image,
            type: 'icons',
          };
          return await uploadImage(iconPayload);
        }),

        ...(screenshotsPaths ?? [])?.map(async screenshotPath => {
          const screenshotName = screenshotPath.split('/').pop();
          const screenshot = screenshots?.find(el => el.name === screenshotName);
          const screenshotPayload = {
            app_id,
            app_name,
            image_path: screenshotPath,
            image_data: screenshot?.image,
            type: 'screenshots',
          };
          return await uploadImage(screenshotPayload);
        }),
      ]);

      // POST Add new App
      const addAppPayload = {
        ...receivedPayload,
        screenshots:
          imageUploadStatuses
            ?.filter(({ type }) => type === 'screenshots')
            ?.filter(({ status }) => status === 'success')
            ?.map(({ path }) => path) ?? [],
        app_icons:
          imageUploadStatuses
            ?.filter(({ type }) => type === 'icons')
            ?.filter(({ status }) => status === 'success')
            ?.map(({ path }) => path) ?? [],
        // icon_file: iconStatus?.path ?? '',
      };
      const response = await post({
        url: ENDPOINTS.APPS,
        body: addAppPayload,
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });

export const loadAppImages = (paths = []) => {
  return new Promise((resolve, reject) => {
    (async () => {
      try {
        if (!paths || !Array.isArray(paths) || !paths.length) {
          resolve([]);
          return;
        }
        const { items } = await post({
          url: ENDPOINTS.GET_ASSETS,
          params: { type: 'get' },
          body: {
            paths: paths,
          },
        });
        const res = items?.map(item => {
          const { request_path, signed_url } = item;
          return {
            image: signed_url || '',
            path: request_path,
            name: request_path?.split('/')?.pop(),
          };
        });
        resolve(res);
      } catch (error) {
        reject(error);
      }
    })();
  });
};

export const updateApp = receivedPayload =>
  new Promise(async (resolve, reject) => {
    try {
      // Resolve the received the data
      const { payload, appIcons = null, screenshots = null } = resolveUpdateAppPayload(receivedPayload);
      // Get the bucket path for icon and/or screenshots
      const bucketPathPayload = {
        app_id: payload?.app_id,
        app_name: payload?.app_name,
        files: [],
      };
      let bucketPathResponse = { data: { icon: [], screenshots: [] } };
      if (Array.isArray(appIcons) && appIcons.length > 0) {
        bucketPathPayload.files.push({ type: 'app_icon', names: appIcons?.map(appIcon => appIcon?.name) ?? [] });
      }
      if (Array.isArray(screenshots) && screenshots.length > 0) {
        bucketPathPayload.files.push({ type: 'screenshots', names: screenshots?.map(screenshot => screenshot?.name) ?? [] });
      }

      if ((appIcons && Array.isArray(appIcons) && appIcons.length) || (screenshots && Array.isArray(screenshots) && screenshots.length)) {
        bucketPathResponse = await post({
          url: ENDPOINTS.POST_GET_BUCKET_PATH,
          body: bucketPathPayload,
        });
      }
      const { app_icon: iconPaths = [], screenshots: screenshotsPaths = [] } = bucketPathResponse;

      // Upload the images and capture the status
      const imageUploadStatuses = await Promise.all([
        ...(iconPaths ?? [])?.map(async iconPath => {
          const iconName = iconPath?.split('/')?.pop();
          const icon = appIcons?.find(el => el.name === iconName);
          const iconPayload = {
            app_id: payload?.app_id,
            app_name: payload?.app_name,
            image_path: iconPath,
            image_data: icon?.image,
            type: 'icons',
          };
          return await uploadImage(iconPayload);
        }),
        ...(screenshotsPaths ?? [])?.map(async screenshotPath => {
          const screenshotName = screenshotPath.split('/').pop();
          const screenshot = screenshots?.find(el => el.name === screenshotName);
          const screenshotPayload = {
            app_id: payload?.app_id,
            app_name: payload?.app_name,
            image_path: screenshotPath,
            image_data: screenshot?.image,
            type: 'screenshots',
          };
          return await uploadImage(screenshotPayload);
        }),
      ]);

      // POST call for updating the App
      const updatePayload = {
        ...payload,
        screenshots: [
          ...payload.screenshots,
          ...(imageUploadStatuses
            ?.filter(({ type }) => type === 'screenshots')
            ?.filter(({ status }) => status === 'success')
            ?.map(({ path }) => path) ?? []),
        ],
        app_icons: [
          ...payload.app_icons,
          ...(imageUploadStatuses
            ?.filter(({ type }) => type === 'icons')
            ?.filter(({ status }) => status === 'success')
            ?.map(({ path }) => path) ?? []),
        ],
      };
      const response = await fetchQuery({ url: ENDPOINTS.APPS, method: 'PUT', body: updatePayload });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });

export const deleteImage = async payload =>
  new Promise(async (resolve, reject) => {
    try {
      const {
        data: { request_status: status },
      } = await deleteRequest({ url: ENDPOINTS.IMAGE, body: payload });
      if (status === 'success') {
        resolve(status);
      } else {
        reject(status);
      }
    } catch (error) {
      reject(error);
    }
  });

export const updateStatus = payload =>
  new Promise(async (resolve, reject) => {
    try {
      const response = await fetchQuery({ url: ENDPOINTS.APPS, method: 'PUT', body: payload });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
