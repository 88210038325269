import AppDetails from '../pages/AppDetails/AppDetails';
import Apps from '../pages/Apps/Apps';
import Login from '../pages/Login/Login';
import UsersGroups from '../pages/UsersGroups/UsersGroups';
import withContainer from '../hoc/withContainer';
import withNavBar from '../hoc/withNavBar';

const ROUTES = Object.freeze([
  {
    path: '/',
    element: Login,
    name: 'Login',
    type: 'auth',
  },
  {
    path: '/apps',
    element: withNavBar(withContainer('Apps', Apps)),
    name: 'Apps',
    default: true,
  },
  {
    path: '/apps/:id',
    element: withNavBar(withContainer('Details', AppDetails)),
    name: 'App Details',
  },
  {
    path: '/usersgroups',
    element: withNavBar(withContainer('Users & Groups', UsersGroups)),
    name: 'Users',
  },
]);

export default ROUTES;
