export const BASE_URL = process.env.REACT_APP_STAGE === 'prod' ? 'https://4f8l0uc0vg.execute-api.us-west-2.amazonaws.com/v1' : 'https://h3eey465tf.execute-api.us-west-2.amazonaws.com/v1';

export const X_API_KEY = process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE === 'prod' ? 'wpEuH9Vz2g4PGrUHxlDHz78pMGiT6P9y2PJohTwd' : 'nXRaZYPhBcb9vWmLeckS5UnLqJerySY9pZsXO20c';

export const ENDPOINTS = {
  ADMIN_USERS: BASE_URL + '/mobilemarket/adminusers',
  APPS: BASE_URL + '/mobilemarket/admin/apps',
  GET_POST_GROUP: BASE_URL + '/mobilemarket/admin/groups',
  USERS: BASE_URL + '/mobilemarket/admin/users',
  IMAGE: BASE_URL + '/mobilemarket/admin/image',
  POST_GET_BUCKET_PATH: BASE_URL + '/mobilemarket/admin/bucket-path',
  RELEASES: BASE_URL + '/mobilemarket/admin/releases',
  SIGNED_URL: BASE_URL + '/mobilemarket/admin/signedurl',
  GET_ASSETS: BASE_URL + '/mobilemarket/common/assets',
};
