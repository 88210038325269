import React, { useState } from 'react';
import { Toggle } from 'rsuite';
import { Cell as RCell } from 'rsuite-table';
import { Icons } from '../../assets';
import moment from 'moment';

const { appIconUnavailable } = Icons;

const Cell = props => {
  const { type, rowData, onChange, dataKey, ...otherProps } = props;
  const [toggleLoading, setToggleLoading] = useState(false);

  const onToggle = () =>
    new Promise(async (resolve, reject) => {
      try {
        setToggleLoading(true);
        resolve(await onChange({ rowData: rowData, key: dataKey, value: !rowData[dataKey] }));
      } catch (error) {
        reject(error);
      } finally {
        setToggleLoading(false);
      }
    });

  switch (type) {
    case 'toggle': {
      return (
        <RCell
          {...otherProps}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            onToggle();
          }}>
          <Toggle loading={toggleLoading} checked={rowData[dataKey]} />
        </RCell>
      );
    }
    case 'icon': {
      return (
        <RCell {...otherProps}>
          <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center ' }}>
            <img src={rowData[dataKey] ? rowData[dataKey] : appIconUnavailable} style={{ maxWidth: 40, aspectRatio: 1 }} alt={appIconUnavailable} />
          </div>
        </RCell>
      );
    }
    case 'date': {
      return <RCell {...otherProps}>{moment(rowData[dataKey]).format('YYYY-MM-DD')}</RCell>;
    }
    case 'date-time': {
      return <RCell {...otherProps}>{moment(rowData[dataKey]).format('YYYY-MM-DD HH:mm:ss')}</RCell>;
    }
    case 'boolean': {
      return <RCell {...otherProps}>{rowData[dataKey] ? 'True' : 'False'}</RCell>;
    }
    default: {
      return <RCell {...otherProps}>{rowData[dataKey]}</RCell>;
    }
  }
};

export default Cell;
