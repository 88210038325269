import React, { useState } from 'react';
import { Nav } from 'rsuite';
import AdminUsers from './pages/AdminUsers/AdminUsers.UsersGroups';
import classes from './UsersGroups.module.css';
import Groups from './pages/Groups/Groups.UsersGroups';
import Users from './pages/Users/Users.UsersGroups';

const UsersGroups = () => {
  const [currentTab, setCurrentTab] = useState('groups');

  return (
    <div className={classes.root}>
      <Nav appearance='subtle' activeKey={currentTab} onSelect={setCurrentTab} style={{ marginBottom: 20 }}>
        <Nav.Item eventKey='groups'>Groups</Nav.Item>
        <Nav.Item eventKey='users'>Users</Nav.Item>
        <Nav.Item eventKey='admin-users'>Admin Users</Nav.Item>
      </Nav>
      {currentTab === 'groups' ? <Groups classes={classes} /> : currentTab === 'users' ? <Users classes={classes} /> : <AdminUsers classes={classes} />}
    </div>
  );
};

export default UsersGroups;
