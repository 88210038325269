export const GROUPS_TABLE_CONFIG = [
  {
    id: 'group_name',
    header: 'Group Name',
    columnOptions: {
      width: 150,
      align: 'center',
      fixed: true,
      flexGrow: 2,
    },
  },
  {
    id: 'group_description',
    header: 'Description',
    columnOptions: {
      width: 250,
      align: 'center',
      fixed: true,
      flexGrow: 2,
    },
  },
  {
    id: 'group_created_date',
    header: 'Created Time',
    type: 'date-time',
    columnOptions: {
      width: 100,
      align: 'center',
      fixed: true,
      flexGrow: 2,
    },
  },
  {
    id: 'group_last_modified_date',
    header: 'Last Modified',
    type: 'date-time',
    columnOptions: {
      width: 100,
      align: 'center',
      fixed: true,
      flexGrow: 2,
    },
  },
];

export const USERS_TABLE_CONFIG = [
  {
    id: 'username',
    header: 'Username',
    columnOptions: {
      width: 150,
      align: 'center',
      fixed: true,
      flexGrow: 2,
    },
  },
  {
    id: 'group_name',
    header: 'Group Name',
    columnOptions: {
      width: 150,
      align: 'center',
      fixed: true,
      flexGrow: 2,
    },
  },
  {
    id: 'user_email',
    header: 'Email',
    columnOptions: {
      width: 150,
      align: 'center',
      fixed: true,
      flexGrow: 2,
    },
  },
  {
    id: 'user_email_verified',
    header: 'Email Verified',
    type: 'boolean',
    columnOptions: {
      width: 150,
      align: 'center',
      fixed: true,
      flexGrow: 2,
    },
  },
  {
    id: 'user_status',
    header: 'Confirmation Status',
    columnOptions: {
      width: 150,
      align: 'center',
      fixed: true,
      flexGrow: 2,
    },
  },
  {
    id: 'user_enabled',
    header: 'Status',
    type: 'boolean',
    columnOptions: {
      width: 150,
      align: 'center',
      fixed: true,
      flexGrow: 2,
    },
  },
  {
    id: 'user_created_date',
    header: 'Created Date',
    type: 'date-time',
    columnOptions: {
      width: 150,
      align: 'center',
      fixed: true,
      flexGrow: 2,
    },
  },
  {
    id: 'user_last_modified_date',
    header: 'Last Modified Date',
    type: 'date-time',
    columnOptions: {
      width: 150,
      align: 'center',
      fixed: true,
      flexGrow: 2,
    },
  },
];

export const ADMIN_USERS_CONFIG = [
  {
    id: 'email_id',
    header: 'Email ID',
    columnOptions: {
      width: 150,
      align: 'center',
      fixed: true,
      flexGrow: 2,
    },
  },
  {
    id: 'is_active',
    header: 'Is Active',
    type: 'toggle',
    columnOptions: {
      width: 150,
      align: 'center',
      fixed: true,
      flexGrow: 2,
    },
  },
];
