import React, { useEffect, useRef, useState } from 'react';
import { getUsers, deleteUser, addNewUser } from './async-handlers';
import { Container, Button, Table, useToaster, Notification, Form, CheckPicker } from 'rsuite';
import CustomCell from '../../../../components/TableCell/TableCell';
import { USERS_TABLE_CONFIG } from '../../config';
import { getGroups } from '../Groups/async-handlers';
import { filterUsers } from './utils';

const { Column, HeaderCell, Cell } = Table;

const Users = ({ classes = {} }) => {
  const toaster = useToaster();
  const formRef = useRef(null);
  const [groups, setGroups] = useState([]);
  const [users, setUsers] = useState({
    loading: true,
    items: [],
    error: null,
  });
  const [showForm, setShowForm] = useState(false);
  const initialValues = {
    username: '',
    email: '',
    groupName: '',
  };
  const [formValue, setFormValue] = useState(initialValues ?? {});
  const [formSubmitting, setFormSubmitting] = useState(false);

  const fetchGroups = async () => {
    try {
      const response = await getGroups();
      setGroups(response.map(item => ({ label: item?.group_name?.split('MM_')?.[1], value: item?.group_name })));
    } catch (error) {}
  };

  const fetchData = async () => {
    try {
      setUsers(prev => ({ ...prev, loading: true }));
      const response = await getUsers();
      setUsers(prev => ({ ...prev, items: response, loading: false }));
    } catch (error) {
      setUsers(prev => ({ ...prev, items: [], error: error }));
    }
  };

  const onDeleteUser = async data => {
    try {
      if (window.confirm(`Are you sure you want to delete ${data?.username}?`)) {
        await deleteUser({ username: data?.username });
        toaster.push(
          <Notification type='success' header='Success'>
            <div style={{ width: 150 }}>{`Deleted ${data?.username} successfully`}</div>
          </Notification>,
          { placement: 'topEnd' },
        );
      }
    } catch (error) {
      toaster.push(
        <Notification type='error' header='Error'>
          <div style={{ width: 150 }}>{error?.message ?? `Unable to delete ${data?.username}. Please try again.`}</div>
        </Notification>,
        { placement: 'topEnd' },
      );
    } finally {
      await fetchData();
    }
  };

  const handleAddNewUser = async () => {
    try {
      setFormSubmitting(true);
      await addNewUser(formValue);
      toaster.push(
        <Notification type='success' header='Success'>
          <div style={{ width: 150 }}>{`Added ${formValue?.username} successfully`}</div>
        </Notification>,
        { placement: 'topEnd' },
      );
      setFormSubmitting(false);
      setShowForm(false);
      formRef?.current?.root?.reset();
      setFormValue(initialValues);
    } catch (error) {
      toaster.push(
        <Notification type='error' header='Error'>
          <div style={{ width: 150 }}>{error?.message ?? `Unable to add ${formValue?.username}. Please try again.`}</div>
        </Notification>,
        { placement: 'topEnd' },
      );
      setFormSubmitting(false);
    } finally {
      await fetchData();
    }
  };

  useEffect(() => {
    fetchGroups();
    fetchData();
  }, []);

  return (
    <Container style={{ height: '100%' }}>
      <div className={classes.dataContainer}>
        <div className={classes.topContainer}>
          {showForm ? (
            <Form ref={formRef} layout='inline' className={classes.form} onChange={formValue => setFormValue(formValue)}>
              <Form.Group className={classes.formGroup} controlId='username'>
                <Form.ControlLabel>Username</Form.ControlLabel>
                <Form.Control shouldResetWithUnmount name='username' style={{ width: 160 }} />
              </Form.Group>
              <Form.Group className={classes.formGroup} controlId='email'>
                <Form.ControlLabel>Email</Form.ControlLabel>
                <Form.Control shouldResetWithUnmount name='email' style={{ width: 160 }} />
              </Form.Group>
              <Form.Group className={classes.formGroup} controlId='groupName'>
                <Form.ControlLabel>Group Name</Form.ControlLabel>
                <Form.Control shouldResetWithUnmount name='groupName' style={{ width: 160 }} accepter={CheckPicker} data={groups} />
              </Form.Group>
              <Button appearance='primary' type='submit' loading={formSubmitting} onClick={() => handleAddNewUser()} className={classes.formBtn}>
                Submit
              </Button>
              <Button
                // type='reset'
                className={classes.formBtn}
                onClick={() => {
                  setFormValue(initialValues);
                  setShowForm(false);
                  formRef?.current?.root?.reset();
                }}>
                Cancel
              </Button>
            </Form>
          ) : (
            <Button appearance='primary' className={classes.createBtn} onClick={() => setShowForm(true)}>
              Create user
            </Button>
          )}
        </div>
        <div className={classes.tableContainer}>
          <Table defaultExpandAllRows={true} width={'100%'} data={filterUsers(users.items, false)} virtualized loading={users.loading} bordered cellBordered rowHeight={50}>
            {USERS_TABLE_CONFIG.map(({ id, header, type, columnOptions }, i) => (
              <Column {...columnOptions} key={i}>
                <HeaderCell style={{ color: '#7a7a7a', fontSize: 12, fontWeight: 700 }}>{header}</HeaderCell>
                <CustomCell type={type} dataKey={id} style={{ color: '#000000' }} />
              </Column>
            ))}
            <Column>
              <HeaderCell style={{ color: '#7a7a7a', fontSize: 12, fontWeight: 700 }}></HeaderCell>
              <Cell align='center'>
                {rowData => (
                  <span>
                    <Button appearance='link' onClick={e => onDeleteUser(rowData)}>
                      Delete
                    </Button>
                  </span>
                )}
              </Cell>
            </Column>
          </Table>
        </div>
        <span className={classes.title}>Individual Users</span>
        <div className={classes.tableContainer}>
          <Table fillHeight={true} defaultExpandAllRows={true} width={'100%'} data={filterUsers(users.items, true)} virtualized loading={users.loading} bordered cellBordered rowHeight={50}>
            {USERS_TABLE_CONFIG.map(({ id, header, type, columnOptions }, i) => (
              <Column {...columnOptions} key={i}>
                <HeaderCell style={{ color: '#7a7a7a', fontSize: 12, fontWeight: 700 }}>{header}</HeaderCell>
                <CustomCell type={type} dataKey={id} style={{ color: '#000000' }} />
              </Column>
            ))}
            <Column>
              <HeaderCell style={{ color: '#7a7a7a', fontSize: 12, fontWeight: 700 }}></HeaderCell>
              <Cell align='center'>
                {rowData => (
                  <span>
                    <Button appearance='link' onClick={e => onDeleteUser(rowData)}>
                      Delete
                    </Button>
                  </span>
                )}
              </Cell>
            </Column>
          </Table>
        </div>
      </div>
    </Container>
  );
};

export default Users;
