import React, { useEffect, useState, useRef } from 'react';
import { Icons, Images } from '../../assets';
import { loadAppScreenShots, API } from './async-handlers';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Placeholder, Button, Notification, useToaster, Toggle } from 'rsuite';
import { useLocation, useNavigate } from 'react-router-dom';
import DeleteReleaseModal from './modals/DeleteReleaseModal';
import DetailRow from './components/DetailRow';
import ReleaseModal from './modals/AddNewReleaseModal';
import styles from './styles';
import TabNav from '../../components/TabNav/TabNav';
import useFetchAppIcons from './hooks/useFetchAppIcons';

const { appIconUnavailable } = Icons;
const { screenshotUnavailable } = Images;

const CustomToggle = ({ data, value, id, onToggle }) => {
  const [loading, setLoading] = React.useState(false);
  const onChange = async (newValue, event) => {
    setLoading(true);
    try {
      await onToggle({
        rowData: data,
        key: id,
        value: newValue,
      });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return <Toggle loading={loading} checked={value} onChange={onChange} />;
};

const AppDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  try {
    if (!location?.state || !location?.state?.app_id || !location?.state?.app_name || !location?.state?.app_icons) {
      throw new Error('App Details not found, redirecting to /apps');
    }
    var {
      app_id,
      app_name,
      s3_bucket_name,
      accessible_by,
      package_name,
      bundle_identifier,
      description,
      app_icons,
      has_notification_support,
      is_active,
      screenshots: screenshotPaths,
    } = location?.state;
  } catch (error) {
    navigate('/apps', { replace: true });
  }
  const toaster = useToaster();
  const { loading: iconsLoading, icons } = useFetchAppIcons(app_icons);
  const Ref = {
    modal: {
      addEdit: useRef(null),
      delete: useRef(null),
    },
  };

  const [activeTab, setActiveTab] = useState('details');
  const [screenshots, setScreenShots] = useState([]);
  const [screenshotsLoading, setScreenshotsLoading] = useState(true);
  const [releases, setReleases] = useState([]);
  const [addReleaseModal, setShowAddReleaseModal] = useState(false);
  const [deleteReleaseModal, setDeleteReleaseModal] = useState(false);

  const init = async () => {
    try {
      setScreenshotsLoading(true);
      const response = await loadAppScreenShots(screenshotPaths);
      setScreenShots(response);
      const { items } = await API.release.get(app_id);
      setReleases(items);
    } catch (error) {
    } finally {
      setScreenshotsLoading(false);
    }
  };

  const mapDataToModal = data => {
    return {
      build_variant: data?.build_variant,
      app_build_path: data?.download_path?.split('/')?.pop(),
      ...data,
    };
  };

  const handleOpenModal = (data = null, isEdit = false) => {
    setShowAddReleaseModal(true);
    Ref.modal.addEdit?.current?.clearForm();
    if (data) {
      Ref.modal.addEdit?.current?.onEdit(mapDataToModal(data), isEdit);
    }
  };

  const handleCloseModal = (clearFormData = false) => {
    if (clearFormData) {
      Ref.modal.addEdit?.current?.clearForm();
    }
    setShowAddReleaseModal(false);
  };

  const getIconPath = (type, buildVariant, icons = []) => {
    let res = '';
    const typeMap = {
      'display-image': '57',
      'full-size-image': '512',
    };
    if (!type || !buildVariant || !(Array.isArray(icons) && icons.length)) {
      return res;
    }
    res = icons?.find(icon => icon?.path?.includes(`${typeMap[type]}_${buildVariant}`))?.path;
    if (!res) {
      res = icons?.find(icon => icon?.path?.includes(`${typeMap[type]}_prod`))?.path;
    }
    return res;
  };

  const handleOnSubmit = async (payload, isEditMode, isBinaryChanged) => {
    const { app, plist_data, s3UploadPath, package_bundle_name, ...data } = payload;
    try {
      const payload = isEditMode ? { ...data, plist_data } : { ...data, app_id, app_name, s3_app_name: s3_bucket_name };
      const responseData = isEditMode ? await API.release.put(payload) : await API.release.post(payload);
      if (isEditMode && isBinaryChanged) {
        const signedUrl = await API.signedUrl.post(s3UploadPath);
        await API.signedUrl.upload(signedUrl, app);
      }
      if (responseData?.upload_url) {
        await API.signedUrl.upload(responseData?.upload_url, app);
      }
      if (responseData?.binary_path) {
        let plistPayload = undefined;
        if (data?.platform?.startsWith('Android')) {
          plistPayload = '';
        } else {
          plistPayload = plist_data;
          // plistPayload.items[0].assets[0].url = responseData?.binary_path;
          plistPayload.items[0].assets = [
            { kind: 'software-package', url: responseData?.binary_path },
            { kind: 'display-image', url: getIconPath('display-image', payload?.build_variant, icons) }, // icons.find(icon => icon?.path?.includes(`57_${payload?.build_variant}`))?.path ?? '' },
            { kind: 'full-size-image', url: getIconPath('full-size-image', payload?.build_variant, icons) }, // icons.find(icon => icon?.path?.includes(`512_${payload?.build_variant}`))?.path ?? '' },
          ];
        }
        await API.release.put({
          ...payload,
          download_path: responseData?.binary_path,
          plist_data: plistPayload ?? '',
        });
      }
      toaster.push(
        <Notification type='success' header='Success'>
          <div style={{ width: 150 }}>{`${isEditMode ? `Updated ` : `Added `} a Release successfully`}</div>
        </Notification>,
        { placement: 'topEnd' },
      );
      await init();
      handleCloseModal(true);
    } catch (error) {
      toaster.push(
        <Notification type='error' header='Error'>
          <div style={{ width: 150 }}>{error?.message ?? `Unable to ${isEditMode ? `update ` : `add new `} release. Please try again.`}</div>
        </Notification>,
        { placement: 'topEnd' },
      );
    }
  };

  const onToggle = async ({ rowData, key, value }) => {
    try {
      if (
        window.confirm(
          `Would you like to ${value ? 'Enable' : 'Disable'} ${key === 'is_latest' ? 'Is Latest' : 'Active'} Status for the release?${
            key === 'is_latest' && value ? `\n Enabling this version will disable other versions.` : ''
          }`,
        )
      ) {
        const payload = {
          app_id: rowData?.app_id,
          version: rowData?.version,
          build_variant: rowData?.build_variant,
          platform: rowData?.platform,
          app_name: rowData?.app_name,
          download_path: rowData?.download_path,
          prevSortKey: `${rowData?.sort_key_version}`,
          [key]: value,
        };
        await API.release.put(payload);
        await init();
      }
    } catch (error) {
      toaster.push(
        <Notification type='error' header='Error'>
          <div style={{ width: 150 }}>{error?.message ?? `Unable to update ${rowData?.app_name}. Please try again.`}</div>
        </Notification>,
        { placement: 'topEnd' },
      );
    }
  };

  const handleDownload = async ({ download_path }) => {
    try {
      const signedUrl = await API.signedUrl.get(download_path);
      window.open(signedUrl);
    } catch (error) {
      toaster.push(
        <Notification type='error' header='Error'>
          <div style={{ width: 150 }}>{error?.message ?? 'Unable to download the release. Please try again.'}</div>
        </Notification>,
        { placement: 'topEnd' },
      );
    }
  };

  const handleDeleteRelease = async payload => {
    try {
      await API.release.delete(payload.app_id, payload.app_name, payload.version);
      toaster.push(
        <Notification type='success' header='Success'>
          <div style={{ width: 150 }}>{`Deleted Release successfully`}</div>
        </Notification>,
        { placement: 'topEnd' },
      );
      await init();
      Ref.modal.delete?.current?.reset();
      setDeleteReleaseModal(false);
    } catch (error) {
      toaster.push(
        <Notification type='error' header='Error'>
          <div style={{ width: 150 }}>{error?.message ?? `Unable to delete release. Please try again.`}</div>
        </Notification>,
        { placement: 'topEnd' },
      );
    }
  };

  const handleOpenDeleteReleaseModal = data => {
    Ref.modal.delete?.current?.set(data);
    setDeleteReleaseModal(true);
  };

  useEffect(() => {
    const jwt = sessionStorage.getItem('jwt') ?? null;
    if (jwt) {
      init();
    } else {
      navigate('/');
    }
  }, []);

  // Material Table
  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'app_id',
        header: 'App ID',
        size: 120,
        enableSorting: false,
        enableColumnActions: false,
      },
      {
        accessorKey: 'version',
        header: 'Version',
        size: 150,
      },
      {
        accessorKey: 'platform',
        header: 'Platform',
        size: 150,
      },
      {
        accessorFn: row => new Date(row.release_date_time),
        header: 'Released On',
        Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(),
        size: 150,
      },
      {
        accessorKey: 'build_variant',
        header: 'Build Variant',
        Cell: ({ cell }) => cell.getValue()?.toLocaleUpperCase(),
        size: 150,
      },
      {
        accessorFn: row => new Date(row.active_from),
        header: 'Active From',
        Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(),
        size: 130,
      },
      {
        accessorKey: 'package_bundle_name',
        header: 'Package Name',
        size: 180,
        enableSorting: false,
      },
      {
        accessorKey: 'is_latest',
        header: 'Is Latest',
        size: 100,
        Cell: ({ row, renderedCellValue }) => <CustomToggle data={row?.original} value={renderedCellValue} id='is_latest' onToggle={onToggle} />,
        // enableSorting: false,
      },
      {
        accessorKey: 'is_active',
        header: 'Is Active',
        size: 100,
        Cell: ({ row, renderedCellValue }) => <CustomToggle data={row?.original} value={renderedCellValue} id='is_active' onToggle={onToggle} />,
        // enableSorting: false,
      },
    ],
    [],
  );
  const table = useMaterialReactTable({
    columns: columns,
    data: releases,
    enableFullScreenToggle: false,
    enableColumnResizing: true,
    enableColumnActions: false,
    // layoutMode: 'grid',
    defaultColumn: { minSize: 100, maxSize: 250 },
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableRowActions: true,
    positionActionsColumn: 'last',
    enableHiding: false,
    muiTableHeadCellProps: {
      sx: {
        fontFamily: 'Montserrat',
      },
    },
    muiTableBodyCellProps: {
      sx: {
        fontFamily: 'Montserrat',
        // fontSize: 13,
      },
    },
    initialState: { density: 'comfortable', columnPinning: { right: ['mrt-row-actions', 'state'] } },
    state: { isLoading: screenshotsLoading },
    enableDensityToggle: false,
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: 'Actions',
        size: 400,
      },
    },
    renderRowActions: ({ row }) => (
      <div style={{ display: 'flex', flex: 1 }}>
        <Button appearance='link' onClick={e => handleOpenModal(row?.original, true)}>
          Edit
        </Button>
        <Button appearance='link' onClick={e => handleDownload(row?.original)}>
          Download
        </Button>
        <Button appearance='link' onClick={e => handleOpenDeleteReleaseModal(row?.original)}>
          Delete
        </Button>
      </div>
    ),
  });

  return (
    <div style={styles.root}>
      <TabNav
        appearance='subtle'
        active={activeTab}
        onSelect={setActiveTab}
        items={[
          { eventKey: 'details', value: 'Details' },
          { eventKey: 'releases', value: 'Releases' },
        ]}>
        <Button
          appearance='primary'
          onClick={() => handleOpenModal({ accessible_by: accessible_by, bundle_identifier, package_name, app_name })}
          style={{ padding: '10px 30px', boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', margin: 5, fontWeight: 700 }}>
          Add New Release
        </Button>
      </TabNav>
      <div style={{ display: 'flex', flexDirection: 'column', padding: 10 }}>
        {activeTab === 'details' ? (
          <table>
            <DetailRow label='Name' value={app_name} />
            <DetailRow label='ID' value={app_id} />
            <DetailRow
              label='Icon'
              value={
                // <img src={icon_url ? icon_url[0] : appIconUnavailable} style={styles.icon} alt={appIconUnavailable} />
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: 20, alignItems: 'baseline' }}>
                  {iconsLoading ? (
                    app_icons?.map(i => <Placeholder.Graph key={`ss-${i}`} active width={100} height={100} />)
                  ) : !icons?.length ? (
                    <span>-</span>
                  ) : (
                    icons?.map(icon => (
                      <div key={icon?.id} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img src={icon?.url} style={styles.icon ?? appIconUnavailable} alt='' />
                        <span style={{ fontSize: 10 }}>{icon?.name}</span>
                      </div>
                    ))
                  )}
                </div>
              }
            />
            <DetailRow label='Description' value={description} />
            <DetailRow label='Active' value={is_active ? 'Yes' : 'No'} />
            <DetailRow label='Notification Support' value={has_notification_support ? 'Yes' : 'No'} />
            <DetailRow
              label='ScreenShots'
              value={
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: 20 }}>
                  {screenshots && screenshots.length ? (
                    screenshots?.map((ss, i) =>
                      screenshotsLoading ? <Placeholder.Graph key={`ss-${i}`} active width={150} height={300} /> : <img src={ss} key={`ssi-${i}`} style={{ width: 150 }} alt={screenshotUnavailable} />,
                    )
                  ) : (
                    <span>-</span>
                  )}
                </div>
              }
            />
          </table>
        ) : (
          <>
            <MaterialReactTable table={table} />
          </>
        )}
      </div>
      <ReleaseModal ref={Ref.modal.addEdit} open={addReleaseModal} onSubmit={handleOnSubmit} onClose={() => setShowAddReleaseModal(false)} data={{ app_icons }} accessibleByList={accessible_by} />
      <DeleteReleaseModal ref={Ref.modal.delete} open={deleteReleaseModal} onClose={() => setDeleteReleaseModal(false)} onSubmit={handleDeleteRelease} />
    </div>
  );
};

export default AppDetails;
