import React from 'react';
import { Header as RHeader } from 'rsuite';
import ExitIcon from '@rsuite/icons/Exit';
import { logout } from '../../services/auth-service';

const Header = ({ title = 'Header' }) => {
  const username = sessionStorage.getItem('username');
  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <RHeader
      style={{
        padding: 15,
        border: '0.5px solid #efefef',
        backgroundColor: '#fff',
        margin: '10px 10px 10px 5px',
        borderRadius: 5,
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
      <label style={{ fontWeight: 600, fontSize: 22 }}>{title}</label>
      <div style={{ display: 'flex', gap: 25, alignItems: 'center' }}>
        {username && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label style={{ fontSize: 11, textAlign: 'end' }}>Logged in as</label>
            <strong>{username}</strong>
          </div>
        )}
        <ExitIcon onClick={handleLogout} style={{ fontSize: 20, cursor: 'pointer' }} />
      </div>
    </RHeader>
  );
};

export default Header;
