import React from 'react';
import ROUTES from './config/routes';
import { Routes, Route, Navigate } from 'react-router-dom';
import AWS_CONFIG from './config/aws-export';
import { Amplify } from 'aws-amplify';

const App = () => {
  Amplify.configure(AWS_CONFIG);
  // Amplify.Logger.LOG_LEVEL = 'DEBUG';

  const defaultRoute = ROUTES.find(route => route?.default)?.path;
  const jwt = sessionStorage.getItem('jwt');

  return (
    <Routes>
      {ROUTES.map(({ path, element: Component }, index) => (
        <Route path={path} element={<Component />} key={index} />
      ))}
      {jwt && defaultRoute ? <Route path='*' element={<Navigate to={defaultRoute} replace />} /> : <Route path='*' element={<Navigate to={'/'} replace />} />}
    </Routes>
  );
};

export default App;
