const REDIRECTS = {
  prod: 'https://appstore-admin.dish.com/',
  np: 'https://appstore-test-admin.dish.com/',
  local: `http://localhost:${process.env.PORT ?? 6075}/`,
};

const redirectURL = REDIRECTS[process.env.REACT_APP_STAGE ?? 'local'];

const AWS_CONFIGS = {
  prod: {
    aws_project_region: 'us-west-2',
    aws_cognito_region: 'us-west-2',
    aws_cognito_identity_pool_id: 'us-west-2:ecc3d424-9227-49d0-bfea-36c22793d6db',
    aws_user_pools_id: 'us-west-2_AA8LPJiPj',
    aws_user_pools_web_client_id: '5mt0frcf7kdfiekqaufnl6c7c9',
    Auth: {
      authenticationFlowType: 'USER_SRP_AUTH',
      oauth: {
        redirectSignIn: redirectURL,
        redirectSignOut: redirectURL,
        responseType: 'code',
        domain: 'itma-ota-users.auth.us-west-2.amazoncognito.com',
        scope: ['phone', 'email', 'openid', 'aws.cognito.signin.user.admin', 'profile', 'https://itma-customscope.com/mobile.market.portal'],
      },
      refreshHandlers: {},
    },
  },
  np: {
    aws_project_region: 'us-west-2',
    aws_cognito_region: 'us-west-2',
    aws_cognito_identity_pool_id: 'us-west-2:ff9f3cfa-1249-46dc-8e32-1fdffbd800fd',
    aws_user_pools_id: 'us-west-2_71D5Np1eQ',
    aws_user_pools_web_client_id: '3e94s4uh81uvd6osgrqoavsag2',
    Auth: {
      authenticationFlowType: 'USER_SRP_AUTH',
      oauth: {
        redirectSignIn: redirectURL,
        redirectSignOut: redirectURL,
        responseType: 'code',
        domain: 'itma-users.auth.us-west-2.amazoncognito.com',
        scope: ['phone', 'email', 'openid', 'aws.cognito.signin.user.admin', 'profile', 'https://itma-customscope.com/mobilemarket.client'],
      },
      refreshHandlers: {},
    },
  },
  local: {
    aws_project_region: 'us-west-2',
    aws_cognito_region: 'us-west-2',
    aws_cognito_identity_pool_id: 'us-west-2:ff9f3cfa-1249-46dc-8e32-1fdffbd800fd',
    aws_user_pools_id: 'us-west-2_71D5Np1eQ',
    aws_user_pools_web_client_id: '3e94s4uh81uvd6osgrqoavsag2',
    Auth: {
      authenticationFlowType: 'USER_SRP_AUTH',
      oauth: {
        redirectSignIn: redirectURL,
        redirectSignOut: redirectURL,
        responseType: 'code',
        domain: 'itma-users.auth.us-west-2.amazoncognito.com',
        scope: ['phone', 'email', 'openid', 'aws.cognito.signin.user.admin', 'profile', 'https://itma-customscope.com/mobilemarket.client'],
      },
      refreshHandlers: {},
    },
  },
};

const AWS_CONFIG = AWS_CONFIGS[process.env.REACT_APP_STAGE ?? 'local'];

export default AWS_CONFIG;
