import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Modal, Button, Uploader } from 'rsuite';
import moment from 'moment';
import pl from 'plist/dist/plist';
import { UserInput } from '../../../components/UserInput/UserInput';
import AlertModal from '../../../components/AlertModal/AlertModal';

const ReleaseModal = ({ open, onClose = () => {}, onSubmit = async () => {}, data = { app_icons: [] }, accessibleByList }, ref) => {
  const platforms = {
    androidMobile: 'AndroidMobile',
    androidTablet: 'AndroidTablet',
    iosMobile: 'iOSMobile',
    iosTablet: 'iOSTablet',
  };
  const binaryFileUploaderRef = useRef(null);
  const alertDialogRef = useRef(null);
  const accessibleByOptions = JSON.parse(sessionStorage.getItem('users')) ?? [];
  const [appName, setAppName] = useState('');
  const [version, setVersion] = useState('');
  const [platform, setPlatform] = useState('');
  const [packageName, setPackageName] = useState('');
  const [bundleIdentifier, setBundleIdentifier] = useState('');
  const [plist, setPlist] = useState('');
  const [isPlistChanged, setPlistChanged] = useState(false);
  const [buildVariant, setBuildVariant] = useState('');
  const [releaseNotes, setReleaseNotes] = useState('');
  const [accessibleBy, setAccessibleBy] = useState({
    options: ['Retailers', 'Technicians', 'Employees'].map(item => ({ label: item, value: item, role: 'Groups' })),
    selected: accessibleByList !== undefined ? accessibleByList.split(',') : [],
  });
  const [activeFrom, setActiveFrom] = useState(new Date());
  const [isLatest, setLatest] = useState(false);
  const [isActive, setActive] = useState(false);
  const [appBuildPath, setAppBuildPath] = useState('');
  const [downloadPath, setDownloadPath] = useState('');
  const [binary, setBinary] = useState({
    name: '',
    size: '',
    type: '',
    binary: '',
    file: '',
  });
  const [isBinaryChanged, setBinaryChanged] = useState(false);
  const [onSubmitLoading, setOnSubmitLoading] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [editData, setEditData] = useState({});
  const [hasChanges, setHasChanges] = useState(false);
  const [isSubmittable, setIsSubmittable] = useState(false);
  const [buildVariantError, setBuildVariantError] = useState(null);
  const [fileError, setFileError] = useState(null);
  const [prevSortKey, setPrevSortKey] = useState(null);

  const onSelectAccessibleBy = (selectedItems = []) => {
    // The selectedItems is an array of items checked at a given instant of time
    let newItems = accessibleBy.options;
    for (let item of selectedItems) {
      if (!accessibleBy.options.includes(item) && item.trim() !== '') {
        newItems.push(item);
      }
    }
    setAccessibleBy({
      options: newItems,
      selected: selectedItems,
    });
  };

  const resolvePlistPayload = plistStr => {
    if (platform?.includes(platforms.iosMobile) || platform?.includes(platforms.iosTablet)) {
      return pl.parse(plistStr);
    }
    return '';
  };

  const resolvePayload = () => {
    let payload = {};
    if (isEditMode) {
      const { sort_key_version, ...rest } = editData;
      payload = { ...rest, prevSortKey: sort_key_version };
    }
    payload = {
      ...payload,
      accessible_by: accessibleBy.selected.join(','),
      active_from: moment(activeFrom).format('YYYY-MM-DD'),
      app_build_path: appBuildPath ?? '',
      app: binary,
      build_variant: buildVariant?.toLocaleLowerCase() ?? '',
      bundle_identifier: bundleIdentifier,
      is_active: !!isActive,
      is_latest: !!isLatest,
      package_name: packageName,
      platform: platform ?? '',
      plist_data: resolvePlistPayload(plist),
      release_notes: releaseNotes ?? '',
      s3UploadPath: downloadPath,
      version: version ?? '',
    };
    return payload;
  };

  const handleOnSubmit = async () => {
    const formData = resolvePayload();
    if (isSubmittable) {
      try {
        setOnSubmitLoading(true);
        await onSubmit(formData, isEditMode, isBinaryChanged);
      } catch (error) {
      } finally {
        setOnSubmitLoading(false);
      }
    }
  };

  const resetForm = () => {
    setVersion('');
    setPlatform('');
    setPackageName('');
    setBundleIdentifier('');
    setBuildVariant('');
    setReleaseNotes('');
    setLatest(false);
    setActive(false);
    setAppBuildPath('');
    setAccessibleBy(prev => ({ ...prev, selected: [] }));
    setBinary({
      name: '',
      size: '',
      type: '',
      binary: '',
      file: '',
    });
    binaryFileUploaderRef?.current?.resetFileInput?.();
    setActiveFrom(new Date());
    setEditMode(false);
    setBinaryChanged(false);
    setHasChanges(false);
    setFileError(null);
    setPrevSortKey(null);
  };

  const handleOnClose = (clearForm = false) => {
    if (!onSubmitLoading) {
      if (clearForm) {
        resetForm();
        onClose();
      } else if (hasChanges) {
        alertDialogRef?.current?.open();
      } else {
        onClose();
      }
    }
  };

  const isValidFileName = str => {
    let [ext, ...fileAttr] = str.split('.').reverse();
    const fileName = fileAttr.reverse().join('.');
    if (!['apk', 'ipa'].includes(ext)) {
      console.error('File Error - Extension Mismatch');
      return false;
    }
    if (![3, 4].includes(fileName.split('_').length)) {
      console.error('File Error - Filename length != 3 | 4');
      return false;
    }
    const [variant, version, ...appName] = fileName.split('_').reverse();
    if (!appName?.join('_') || !variant || !version) {
      console.error(`File Error - Invalid Name = ${appName?.join('_')} , Version = ${version} , Variant = ${variant}`);
      return false;
    }
    if (!/^\d+(\.\d+){0,2}$/.test(version)) {
      console.error(`File Error - Invalid Version = ${version}`);
      return false;
    }
    return true;
  };

  const isValidSortKey = (str, setter, error = '') => {
    if (!isEditMode) {
      setter(str);
      setFileError(null);
      return true;
    }
    if (!editData?.sort_key_version?.includes(str)) {
      setFileError(error ? error : 'Version/Variant mismatch');
      return false;
    }
    setter(str);
    return true;
  };

  const onSelectAppFile = (files = []) => {
    setBinaryChanged(true);
    const file = files?.pop();
    if (file) {
      const {
        blobFile: { name, size, type },
      } = file;
      let reader = new FileReader();
      reader.readAsDataURL(file?.blobFile);
      reader.onloadend = () => {
        setFileError(!isValidFileName(name) ? `Incorrect file name of the selected file.` : null);
        if (name?.endsWith('.apk')) {
          const fileName = name?.split('.apk')?.[0];
          const [variant, version, ...appName] = fileName?.split('_')?.reverse();
          isValidSortKey(version, setVersion);
          isValidSortKey(variant?.toLocaleLowerCase(), setBuildVariant);
          if (appName.includes('tablet') || appName.includes('Tablet')) {
            setPlatform(platforms.androidTablet);
          } else {
            setPlatform(platforms.androidMobile);
          }
          setBinary({
            name: appName?.join('_')?.toLocaleLowerCase(),
            size,
            type: 'application/vnd.android.package-archive',
            binary: reader.result,
            file: file?.blobFile,
          });
        } else {
          const fileName = name?.split('.ipa')?.[0];
          const [variant, version, ...appName] = fileName?.split('_')?.reverse();
          isValidSortKey(version, setVersion);
          isValidSortKey(variant?.toLocaleLowerCase(), setBuildVariant);
          if (appName.includes('tablet') || appName.includes('Tablet')) {
            setPlatform(platforms.iosTablet);
          } else {
            setPlatform(platforms.iosMobile);
          }
          setBinary({
            name: appName?.join('_')?.toLocaleLowerCase(),
            size,
            type: 'application/octet-stream',
            binary: reader.result,
            file: file?.blobFile,
          });
        }
      };
      setAppBuildPath(name);
    }
  };

  useImperativeHandle(ref, () => ({
    onEdit: (editData = null, editMode = false) => {
      if (editData) {
        setAppName(editData?.app_name);
        setVersion(editData?.version);
        setActiveFrom(editData?.active_from ? new Date(editData?.active_from) : new Date());
        setPlatform(editData?.platform);
        setPackageName(editData?.package_name);
        setBundleIdentifier(editData?.bundle_identifier);
        setBuildVariant(editData?.build_variant);
        setReleaseNotes(editData?.release_notes);
        setLatest(editData?.is_latest);
        setActive(editData?.is_active);
        setAppBuildPath(editData?.app_build_path);
        setDownloadPath(editData?.download_path);
        setPlist(editData?.plist_data ? pl.build(editData?.plist_data) : '');
        setEditData(editData);
        setEditMode(editMode);
        setAccessibleBy(prev => ({
          ...prev,
          selected: editData?.accessible_by
            ?.split(',')
            ?.filter(el => el)
            ?.sort(),
        }));
        setPrevSortKey(`${editData?.sort_key_version}`);
      }
    },
    clearForm: () => {
      resetForm();
    },
  }));

  useEffect(() => {
    if (isEditMode) {
      const existingAccessibleBy = editData?.accessible_by
        ?.split(',')
        ?.filter(el => el)
        ?.sort()
        ?.join(',');
      const presentAccessibleBy = accessibleBy?.selected?.sort().join(',');
      const hasAccessibleByChanges = existingAccessibleBy !== presentAccessibleBy;
      const formHasChanges =
        version !== editData?.version ||
        platform !== editData?.platform ||
        packageName !== editData?.package_name ||
        bundleIdentifier !== editData?.bundle_identifier ||
        buildVariant !== editData?.build_variant ||
        releaseNotes !== editData?.release_notes ||
        isLatest !== editData?.is_latest ||
        isActive !== editData?.is_active ||
        appBuildPath !== editData?.app_build_path ||
        new Date(activeFrom).toISOString()?.split('T')?.[0] !== editData?.active_from ||
        hasAccessibleByChanges ||
        isBinaryChanged ||
        isPlistChanged;
      setHasChanges(formHasChanges);
      setIsSubmittable(formHasChanges);
    } else {
      const newFormHasChanges =
        version !== '' ||
        platform !== '' ||
        buildVariant !== '' ||
        releaseNotes !== '' ||
        appBuildPath !== '' ||
        new Date(activeFrom).toISOString()?.split('T')?.[0] !== new Date().toISOString()?.split('T')?.[0] ||
        accessibleBy?.selected?.length > 0;
      setHasChanges(newFormHasChanges);
      const canSubmitForm = version !== '' && platform !== '' && buildVariant !== '' && appBuildPath !== '' && accessibleBy?.selected?.length > 0;
      setIsSubmittable(canSubmitForm);
    }
  }, [
    accessibleBy,
    activeFrom,
    appBuildPath,
    appName,
    binary.size,
    bundleIdentifier,
    downloadPath,
    editData,
    isBinaryChanged,
    isEditMode,
    isLatest,
    isActive,
    isPlistChanged,
    packageName,
    platform,
    releaseNotes,
    buildVariant,
    version,
  ]);

  // useEffect to update the plist based on appName, bundleIdentifier, downloadPath, version
  useEffect(() => {
    if (isEditMode) {
      const plD = editData?.plist_data?.items?.[0];
      const json = {
        items: [
          {
            assets: plD?.assets ?? [{ kind: 'software-package', url: '' }],
            metadata: {
              ...plD?.metadata,
              'bundle-identifier': bundleIdentifier,
              'bundle-version': version,
            },
          },
        ],
      };
      setPlist(pl.build(json));
    } else {
      const json = {
        items: [
          {
            assets: [
              {
                kind: 'software-package',
                url: downloadPath,
              },
            ],
            metadata: {
              'bundle-identifier': bundleIdentifier,
              'bundle-version': version,
              kind: 'software',
              subtitle: 'Dish Network, LLC',
              title: appName,
            },
          },
        ],
      };
      setPlist(pl.build(json));
    }
  }, [appName, bundleIdentifier, downloadPath, version, isEditMode, editData?.plist_data?.items]);

  // useEffect to validate the existence of the icon for the entered build variant
  useEffect(() => {
    if (buildVariant && !data?.app_icons?.find(icon => icon?.split('.')?.[0]?.split('_')?.pop() === buildVariant)) {
      setBuildVariantError(`App icon doesn't exist for this build variant. Icon of PROD will be used.`);
    } else {
      setBuildVariantError(null);
    }
  }, [buildVariant]);

  return (
    <Modal backdrop='static' keyboard={false} open={open} onClose={() => handleOnClose()} size='lg'>
      <Modal.Header>
        <Modal.Title style={{ fontWeight: 600, fontSize: 18 }}>{isEditMode ? `Edit Release` : `Add New Release`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {/* <UserInput type='file' label='App Binary' value={binary?.binary} onChange={onSelectBinary} ref={binaryFileUploaderRef} multiple={false} /> */}
          <div style={{ margin: '0px 0px 24px 8px', width: '90%' }}>
            <label style={{ fontFamily: 'Source Sans Pro, sans-serif', fontSize: 16, marginBottom: 16, fontWeight: 600, color: '#413839' }}>App Binary(*)</label>
            <Uploader fileListVisible={false} autoUpload={false} accept='.apk,.ipa' draggable onChange={onSelectAppFile}>
              <div style={{ height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {appBuildPath ? (
                  <span>
                    Selected <strong>{appBuildPath}</strong>
                  </span>
                ) : (
                  <span>
                    Click or Drag <strong>*.apk</strong> or <strong>*.ipa</strong> file to this area to upload
                    <br />
                    Expected file name : <strong>appName</strong>_<strong>version</strong>_<strong>variant</strong>.<strong>ext</strong> / <strong>appName</strong>_tablet_<strong>version</strong>_
                    <strong>variant</strong>.<strong>ext</strong>
                    <br />
                    Eg: testApp_1.0.0_prod.apk / testApp_tablet_1.0.0_prod.apk
                  </span>
                )}
              </div>
            </Uploader>
            {fileError ? <div style={{ color: 'red' }}>{fileError ?? `Incorrect file name of the selected file.`}</div> : null}
          </div>
          <UserInput label={'Version'} placeholder={'Eg- 1.2.3'} value={version} onChange={setVersion} required={true} disabled={!!isEditMode} bottom={24} />
          <UserInput
            label={'Platform'}
            noDefault={true}
            placeholder={'Eg- android, ios'}
            type='dropdown'
            options={isEditMode ? [platform] : Object.values(platforms)}
            value={platform}
            onChange={setPlatform}
            required={true}
            disabled={!!isEditMode}
            bottom={24}
            style={{ width: 'max-content !important' }}
          />
          {platform ? (
            <UserInput
              label={['iOSMobile', 'iOSTablet']?.includes(platform) ? 'Bundle Identifier' : 'Package Name'}
              placeholder={'Eg- com.abc.xyz'}
              value={['iOSMobile', 'iOSTablet']?.includes(platform) ? bundleIdentifier : packageName}
              onChange={['iOSMobile', 'iOSTablet']?.includes(platform) ? setBundleIdentifier : setPackageName}
              required={true}
              bottom={24}
            />
          ) : null}
          <UserInput
            type='textarea'
            label={'Release Notes'}
            placeholder={version ? `Enter release notes for v${version}` : 'Enter release notes'}
            value={releaseNotes}
            onChange={setReleaseNotes}
            required={false}
            bottom={16}
            rows={5}
          />
          <UserInput
            label={'Release Variant'}
            placeholder={'Eg- test, prod'}
            value={buildVariant}
            onChange={setBuildVariant}
            required={true}
            disabled={!!isEditMode}
            bottom={buildVariantError ? 0 : 24}
          />
          {buildVariantError ? <div style={{ marginLeft: 8, marginTop: 3, marginBottom: 16, color: 'red' }}>{buildVariantError}</div> : null}
          {['iOSMobile', 'iOSTablet']?.includes(platform) ? (
            <UserInput
              type='textarea'
              label={'plist'}
              value={plist}
              onChange={val => {
                setPlistChanged(true);
                setPlist(val);
              }}
              required={false}
              bottom={16}
              rows={14}
            />
          ) : null}
          <UserInput
            type='tag-picker'
            placeholder='Pick/Add a Group or User'
            label={'Accessible By'}
            options={accessibleByOptions ?? accessibleBy.options}
            value={accessibleBy.selected}
            onChange={onSelectAccessibleBy}
            required={true}
            bottom={24}
          />
          <UserInput
            type='date'
            label='Active From'
            placeholder='Select a date'
            value={activeFrom}
            disabledDate={date => moment?.(date)?.isBefore(moment(), 'day')}
            onChange={setActiveFrom}
            bottom={24}
          />
          {/* <UserInput
            label={'Build Path'}
            plaintext
            placeholder={'Enter the binary name with extension'}
            disabled={!!binary?.binary}
            onChange={setAppBuildPath}
            value={binary?.name ? binary?.name : appBuildPath ? appBuildPath : '-'}
            bottom={24}
          /> */}
          <UserInput type='checkbox' value={isLatest} label={'Is Latest?'} onChange={setLatest} bottom={24} />
          <UserInput type='checkbox' value={isActive} label={'Is Active?'} onChange={setActive} bottom={24} />
        </div>
        <AlertModal ref={alertDialogRef} body={`There are some unsaved changes. Proceeding will discard the changes. Do you like to proceed?`} onOk={() => handleOnClose(true)} />
      </Modal.Body>
      <Modal.Footer>
        <Button loading={onSubmitLoading} disabled={fileError} onClick={handleOnSubmit} appearance='primary' style={{ padding: '10px 30px' }}>
          Submit
        </Button>
        <Button disabled={onSubmitLoading} onClick={() => handleOnClose()} appearance='subtle'>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default forwardRef(ReleaseModal);
