import React from 'react';
import { Container } from 'rsuite';
import { Hub } from 'aws-amplify';
import { isUserAuthenticated } from '../services/auth-service';
import Content from '../components/Layout/Content';
import Footer from '../components/Layout/Footer';
import Header from '../components/Layout/Header';
import UnauthorizedModal from '../components/UnauthorizedModal/UnauthorizedModal';

const withContainer = (header = '', Component) =>
  class extends React.Component {
    constructor() {
      super();
      this.state = {
        unAuthModal: false,
        errorCode: null,
      };
      isUserAuthenticated().catch(err => {
        this.setState({ unAuthModal: true, errorCode: 401 });
      });
      Hub.listen('Apps', ({ payload: { event, data } }) => {
        switch (event) {
          case 'authorization-failure': {
            this.setState({ unAuthModal: true, errorCode: data?.status });
            break;
          }
          default: {
          }
        }
      });
    }

    render() {
      return (
        <Container style={{ width: '100%', overflow: 'hidden' }}>
          <Header title={header} />
          <Content>
            <Component />
          </Content>
          <Footer />
          <UnauthorizedModal open={this.state.unAuthModal} errorCode={this.state.errorCode} />
        </Container>
      );
    }
  };

export default withContainer;
