import React from 'react';
import { Nav } from 'rsuite';
import styles from './styles';

const TabNav = ({ active, onSelect, items = [], children, ...props }) => {
  return (
    <Nav {...props} activeKey={active} onSelect={onSelect} style={styles.root}>
      <div style={styles.navContainer}>
        {items.map((item, i) => (
          <Nav.Item key={i} style={styles.navItem} eventKey={item.eventKey}>
            {item.value}
          </Nav.Item>
        ))}
      </div>
      {children}
    </Nav>
  );
};

export default TabNav;
