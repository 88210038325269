import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'rsuite';
import { logout } from '../../services/auth-service';
import { useNavigate } from 'react-router';

const IS_TIMER_ENABLED = false;

const UnauthorizedModal = ({ open = false, errorCode = 401 }) => {
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(10);

  const errorMessage = React.useMemo(
    () => ({
      401: {
        code: 401,
        title: '401 - Unauthorized',
        message: 'Invalid credentials / No current authenticated user.',
        btnTitle: 'Redirect to Login',
        btnAction: () => {
          navigate('/', { replace: true });
        },
      },
      403: {
        code: 403,
        title: '403 - Forbidden',
        message: 'You are not authorized to use this portal. Please try logging in with another credentials.',
        btnTitle: IS_TIMER_ENABLED ? `Logging out${timeLeft && timeLeft > 0 ? ` in ${timeLeft} sec` : `...`}` : `Logout`,
        btnAction: () => {
          setTimeLeft(null);
          logout();
        },
      },
    }),
    [],
  );

  useEffect(() => {
    let intervalId = null;
    if (open && IS_TIMER_ENABLED) {
      if (timeLeft === 0) {
        logout();
        setTimeLeft(null);
      }
      intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [timeLeft, open]);

  return (
    open === true && (
      <Modal backdrop='static' size={'sm'} open={open} backdropClassName={'unauthorized-modal-backdrop'}>
        <Modal.Header closeButton={false}>
          <Modal.Title style={{ fontWeight: 600 }}>{errorMessage[errorCode]?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorMessage[errorCode]?.message}</Modal.Body>
        <Modal.Footer>
          <Button onClick={errorMessage[errorCode]?.btnAction} appearance='primary' color='red'>
            {errorMessage[errorCode]?.btnTitle}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  );
};

export default UnauthorizedModal;
