import React, { useEffect, useRef, useState } from 'react';
import { getAdminUsers, createAdminUser, updateAdminUser, deleteAdminUser } from './async-handlers';
import { Container, Button, Table, useToaster, Notification, Form, SelectPicker } from 'rsuite';
import CustomCell from '../../../../components/TableCell/TableCell';
import { ADMIN_USERS_CONFIG } from '../../config';

const { Column, HeaderCell, Cell } = Table;

const AdminUsers = ({ classes = {} }) => {
  const toaster = useToaster();
  const formRef = useRef(null);
  const [adminUusers, setAdminUsers] = useState({
    loading: true,
    items: [],
    error: null,
  });
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    email_id: '',
    is_active: false,
  });
  const [formSubmitting, setFormSubmitting] = useState(false);

  const fetchData = async () => {
    try {
      setAdminUsers(prev => ({ ...prev, loading: true }));
      const response = await getAdminUsers();
      setAdminUsers(prev => ({ ...prev, items: response?.items ?? [], loading: false }));
    } catch (error) {
      setAdminUsers(prev => ({ ...prev, items: [], error: error }));
    }
  };

  const onDeleteUser = async data => {
    try {
      if (window.confirm(`Are you sure you want to delete ${data?.email_id}?`)) {
        await deleteAdminUser(`email_id=${data?.email_id}`);
        toaster.push(
          <Notification type='success' header='Success'>
            <div style={{ width: 150 }}>{`Deleted ${data?.email_id} successfully`}</div>
          </Notification>,
          { placement: 'topEnd' },
        );
      }
    } catch (error) {
      toaster.push(
        <Notification type='error' header='Error'>
          <div style={{ width: 150 }}>{error?.message ?? `Unable to delete ${data?.email_id}. Please try again.`}</div>
        </Notification>,
        { placement: 'topEnd' },
      );
    } finally {
      await fetchData();
    }
  };

  const handleAddNewAdminUser = async () => {
    try {
      setFormSubmitting(true);
      await createAdminUser({ ...formData, is_active: !!formData.is_active });
      toaster.push(
        <Notification type='success' header='Success'>
          <div style={{ width: 150 }}>{`Added ${formData?.email_id} successfully`}</div>
        </Notification>,
        { placement: 'topEnd' },
      );
      setFormSubmitting(false);
      setShowForm(false);
      formRef?.current?.root?.reset();
    } catch (error) {
      toaster.push(
        <Notification type='error' header='Error'>
          <div style={{ width: 150 }}>{error?.message ?? `Unable to add ${formData?.email_id}. Please try again.`}</div>
        </Notification>,
        { placement: 'topEnd' },
      );
      setFormSubmitting(false);
    } finally {
      await fetchData();
    }
  };

  const handleUpdateAdminUser = async ({ key, value, rowData }) => {
    try {
      await updateAdminUser({
        ...rowData,
        [key]: value,
      });
      toaster.push(
        <Notification type='success' header='Success'>
          <div style={{ width: 150 }}>{`Updated ${rowData?.email_id} successfully`}</div>
        </Notification>,
        { placement: 'topEnd' },
      );
    } catch (error) {
      toaster.push(
        <Notification type='error' header='Error'>
          <div style={{ width: 150 }}>{error?.message ?? `Unable to update ${rowData?.email_id}. Please try again.`}</div>
        </Notification>,
        { placement: 'topEnd' },
      );
    } finally {
      await fetchData();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container style={{ height: '100%' }}>
      <div className={classes.dataContainer}>
        <div className={classes.topContainer}>
          {showForm ? (
            <Form layout='inline' ref={formRef} className={classes.form} onChange={setFormData}>
              <Form.Group className={classes.formGroup} controlId='email_id'>
                <Form.ControlLabel>Email ID</Form.ControlLabel>
                <Form.Control name='email_id' type='email' style={{ width: 160 }} />
                <Form.HelpText tooltip>Required</Form.HelpText>
              </Form.Group>

              <Form.Group className={classes.formGroup} controlId='is_active'>
                <Form.ControlLabel>Is Active</Form.ControlLabel>
                <Form.Control
                  name='is_active'
                  style={{ width: 100 }}
                  accepter={SelectPicker}
                  data={[
                    { label: 'True', value: 1 },
                    { label: 'False', value: 0 },
                  ]}
                  searchable={false}
                />
              </Form.Group>
              <Button appearance='primary' type='submit' loading={formSubmitting} onClick={() => handleAddNewAdminUser()} className={classes.formBtn}>
                Submit
              </Button>
              <Button type='reset' className={classes.formBtn} onClick={() => setShowForm(false)}>
                Cancel
              </Button>
            </Form>
          ) : (
            <Button appearance='primary' className={classes.createBtn} onClick={() => setShowForm(true)}>
              Create Admin User
            </Button>
          )}
        </div>
        <div className={classes.tableContainer}>
          <Table fillHeight={true} defaultExpandAllRows={true} width={'100%'} data={adminUusers.items} virtualized loading={adminUusers.loading} bordered cellBordered rowHeight={50}>
            {ADMIN_USERS_CONFIG.map(({ id, header, type, columnOptions }, i) => (
              <Column {...columnOptions} key={i}>
                <HeaderCell style={{ color: '#7a7a7a', fontSize: 12, fontWeight: 700 }}>{header}</HeaderCell>
                <CustomCell type={type} dataKey={id} style={{ color: '#000000' }} onChange={handleUpdateAdminUser} />
              </Column>
            ))}
            <Column>
              <HeaderCell style={{ color: '#7a7a7a', fontSize: 12, fontWeight: 700 }}></HeaderCell>
              <Cell align='center'>
                {rowData => (
                  <span>
                    <Button appearance='link' onClick={e => onDeleteUser(rowData)}>
                      Delete
                    </Button>
                  </span>
                )}
              </Cell>
            </Column>
          </Table>
        </div>
      </div>
    </Container>
  );
};

export default AdminUsers;
