import { Auth } from 'aws-amplify';

export const signIn = ({ username, password }) =>
  new Promise((resolve, reject) => {
    Auth.signIn(username, password)
      .then(user => {
        const { challengeName } = user;
        if (challengeName && challengeName === 'NEW_PASSWORD_REQUIRED') {
          reject({ errorMessage: 'Password reset required', userObject: user });
        }
        resolve(user);
      })
      .catch(err => {
        reject(err);
      });
  });

export const isUserAuthenticated = () =>
  new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        sessionStorage.setItem('username', JSON.parse(user?.attributes?.identities)?.[0]?.userId);
        const jwtToken = user?.signInUserSession?.accessToken?.jwtToken;
        const currentJwt = sessionStorage.getItem('jwt');
        if (jwtToken !== currentJwt) {
          sessionStorage.setItem('jwt', jwtToken);
        }
        resolve(user);
      })
      .catch(error => {
        sessionStorage.clear();
        localStorage.clear();
        reject(error);
      });
  });

export const refreshToken = () =>
  new Promise((resolve, reject) => {
    Auth.currentSession()
      .then(session => {
        const jwtToken = session.getAccessToken().getJwtToken();
        sessionStorage.setItem('jwt', jwtToken);
        resolve(jwtToken);
      })
      .catch(err => reject(err));
  });

export const logout = () =>
  new Promise(async (resolve, reject) => {
    try {
      sessionStorage.clear();
      // localStorage.clear();
      await Auth.signOut({ global: true });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
